import React from "react";

// import "./index.css";
import {
  Column,
  ColumnDef,
  ColumnFiltersState,
  FilterFn,
  SortingFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  sortingFns,
  useReactTable,
} from "@tanstack/react-table";

// A TanStack fork of Kent C. Dodds' match-sorter library that provides ranking information
import {
  RankingInfo,
  rankItem,
  compareItems,
} from "@tanstack/match-sorter-utils";
import { ContainerApiProps, UserApiProps } from "../../../types/apiType";
import { FaFileInvoiceDollar, FaPencilAlt } from "react-icons/fa";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import {
  selectSelects,
  toggleItemAction,
} from "../../../redux/slices/SelectCheckbox/SelectCheckboxSlice";
import { useSelector } from "react-redux";
import { selectContainer } from "../../../redux/slices/Container/containerSlice";
import { Socket } from "socket.io-client";
import LoadingComponent from "../../../components/LoadingComponent";
import { BsFillCloudUploadFill, BsImages } from "react-icons/bs";
import { containerStatusHelper } from "../../../utils/StatusHelper";
import { AiFillDollarCircle } from "react-icons/ai";
import { TbListNumbers } from "react-icons/tb";
import { createSelector } from "@reduxjs/toolkit";
import { GoContainer } from "react-icons/go";

declare module "@tanstack/react-table" {
  //add fuzzy filter to the filterFns
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

// Define a custom fuzzy filter function that will apply ranking info to rows (using match-sorter utils)
const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

// Define a custom fuzzy sort function that will sort by rank if the row has ranking information
const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank!,
      rowB.columnFiltersMeta[columnId]?.itemRank!
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

const containerDataSelector = createSelector(
  selectContainer,
  (container) => container.data
);

interface ListItemProps {
  dataContainer: Array<ContainerApiProps>;
  openFormUpdate: (id: string) => void;
  openFormRent: (id: string) => void;
  openFormUpdateImages: (id: string) => void;
  openFormUpdateImagesAfterRepair: (id: string) => void;
  openFormUpdateImagesGateOut: (id: string) => void;
  openFormSellCont: (id: string) => void;
  openFormGateIn: (id: string) => void;
  openSlide: (id: string, currentNameField: string) => void;
  socket: Socket;
  userAuth?: UserApiProps;
  loadingImagesGateOut?: boolean;
  loadingImagesGateIn?: boolean;
  loadingImagesMNR?: boolean;
  containerUpdate?: ContainerApiProps;
  openFormSellMany: () => void;
}
export const TableContainerUser: React.FC<ListItemProps> = ({
  dataContainer,
  openFormUpdate,
  openFormRent,
  socket,
  userAuth,
  openSlide,
  openFormUpdateImages,
  openFormUpdateImagesAfterRepair,
  openFormUpdateImagesGateOut,
  openFormSellCont,
  openFormGateIn,
  loadingImagesGateOut,
  loadingImagesGateIn,
  loadingImagesMNR,
  containerUpdate,
  openFormSellMany,
}) => {
  const handleOpenFormUpdate = (id: string | undefined) => {
    if (id !== undefined) {
      openFormUpdate(id);
    }
  };
  const rerender = React.useReducer(() => ({}), {})[1];

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = React.useState("");

  const dispatch = useAppDispatch();

  const getSelects = useSelector(selectSelects);
  const { selected } = getSelects;

  const dataContainerInStore = useSelector(containerDataSelector);

  //!check header item selection
  // const containerData = useSelector(selectContainer);
  // const { data: dataContainerInStore } = containerData;
  // const [lengthData, setLengthData] = useState(0);
  // const handleSelectAll = () => {
  //   const dataListSelected = dataContainerInStore
  //     .filter((item) => item.contStatus === 0)
  //     .map((item) => {
  //       return { _id: item._id };
  //     });
  //   setLengthData(dataListSelected.length);
  //   dispatch(selectAllAction(dataListSelected));
  // };
  //!

  // TODO handle function
  const handleSelection = (itemSelected: ContainerApiProps) => {
    const { _id, containerNumber } = itemSelected;
    dispatch(toggleItemAction({ _id, containerNumber }));
  };
  const openSlideShow = (id: string, currentNameField: string) => {
    openSlide(id, currentNameField);
  };
  const openFormUpdateImagesForm = (id: string) => {
    openFormUpdateImages(id);
  };
  const openFormUpdateImagesAfterRepairForm = (id: string) => {
    openFormUpdateImagesAfterRepair(id);
  };
  const openFormUpdateImagesGateOutForm = (id: string) => {
    openFormUpdateImagesGateOut(id);
  };
  const handleOpenFormSellCont = (id: string | undefined) => {
    if (id !== undefined) {
      openFormSellCont(id);
    }
  };

  const handleOpenFormUpdateGateIn = (id: string | undefined) => {
    if (id !== undefined) {
      openFormGateIn(id);
    }
  };

  const handleOpenFormRent = (id: string | undefined) => {
    if (id !== undefined) {
      openFormRent(id);
    }
  };
  // TODO END

  const columns = React.useMemo<ColumnDef<ContainerApiProps, any>[]>(
    () => [
      {
        accessorKey: "STT",
        header: "STT",
        size: 20,
      },
      {
        accessorKey: "Select box",
        header: "Chọn",
        cell: ({ row }) => (
          <div className="px-1 py-1 whitespace-nowrap text-sm text-gray-800">
            {row.original.contStatus !== -1 && (
              <div className="flex items-center">
                <input
                  id="checkbox-all-search"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  checked={selected
                    ?.map((item) => item._id)
                    .includes(row.original._id!)}
                  onChange={() => handleSelection(row.original)}
                />
                <label htmlFor="checkbox-all-search" className="sr-only">
                  checkbox
                </label>
                <label className="text-red-600" key={row.original._id}>
                  {selected?.map((itemSelected, index) => {
                    return itemSelected._id === row.original._id
                      ? index + 1
                      : undefined;
                  })}
                </label>
              </div>
            )}
          </div>
        ),
      },
      {
        accessorKey: "Số hóa đơn",
        header: () => <span>Số hóa đơn</span>,
        accessorFn: (row) => row.invoiceNumber,
        cell: (info) => info.getValue(),
        filterFn: "includesStringSensitive", //note: normal non-fuzzy filter column
      },
      {
        accessorKey: "Số booking",
        header: () => <span>Số booking</span>,
        accessorFn: (row) => row.bookingNumber,
        cell: (info) => info.getValue(),
        filterFn: "includesStringSensitive", //note: normal non-fuzzy filter column
      },
      {
        accessorKey: "Nhà cung cấp",
        header: () => <span>Nhà cung cấp</span>,
        accessorFn: (row) => row?.supplier?.label,
        cell: (info) => info.getValue(),
        filterFn: "includesStringSensitive", //note: normal non-fuzzy filter column
      },
      {
        accessorKey: "Vị trí container",
        accessorFn: (row) => row.serviceSupplier?.label, //note: normal non-fuzzy filter column - case sensitive
        // id: "serviceSupplier",
        cell: (info) => info.getValue(),
        header: () => <span>Vị trí</span>,
        filterFn: "includesString", //note: normal non-fuzzy filter column - case insensitive
      },
      {
        accessorKey: "Hãng khai thác",
        accessorFn: (row) => row.lineOperation?.label, //note: normal non-fuzzy filter column - case sensitive
        // id: "serviceSupplier",
        cell: (info) => info.getValue(),
        header: () => <span>Hãng khai thác</span>,
        filterFn: "includesString", //note: normal non-fuzzy filter column - case insensitive
      },
      {
        accessorKey: "Số container",
        header: () => <span>Số container</span>,
        accessorFn: (row) => row.containerNumber,
        cell: (info) => info.getValue(),
        filterFn: "includesStringSensitive", //note: normal non-fuzzy filter column
      },
      {
        accessorKey: "Tình trạng container",
        header: () => <span>Tình trạng container</span>,
        accessorFn: (row) => row.containerCondition,
        cell: (info) => info.getValue(),
        filterFn: "includesStringSensitive", //note: normal non-fuzzy filter column
        maxSize: 1000,
      },
      {
        accessorKey: "Kích thước container",
        header: () => <span>Kích thước container</span>,
        accessorFn: (row) => row.size,
        cell: (info) => info.getValue(),
        filterFn: "includesStringSensitive", //note: normal non-fuzzy filter column
      },
      {
        accessorKey: "Loại máy",
        header: () => <span>Loại máy</span>,
        accessorFn: (row) => row.brandOfUnit?.label,
        cell: (info) => info.getValue(),
        filterFn: "includesStringSensitive", //note: normal non-fuzzy filter column
      },
      {
        accessorKey: "Tuổi",
        header: () => <span>Tuổi</span>,
        accessorFn: (row) => row.yearOfManufacture,
        cell: (info) => info.getValue(),
        filterFn: "includesStringSensitive", //note: normal non-fuzzy filter column
      },
      {
        id: "Hình ảnh",
        header: "Hình ảnh",

        cell: ({ row }) => (
          <>
            {loadingImagesGateIn &&
            containerUpdate?._id === row.original._id ? (
              <LoadingComponent />
            ) : !row.original?.hasImage && !row.original?.images?.length ? (
              <div className="flex items-center justify-center gap-9">
                <div>Chưa có hình</div>
                {userAuth?.role === 3 && (
                  <div
                    className="bg-blue-600 rounded flex items-center mt-2 gap-2 text-white group cursor-pointer "
                    onClick={() => openFormUpdateImagesForm(row.original?._id!)}
                  >
                    <BsFillCloudUploadFill className="w-5 h-5 cursor-pointer" />
                    Upload
                  </div>
                )}
              </div>
            ) : (
              <div
                className="flex gap-4 divide-x text-white group cursor-pointer"
                onClick={() => openSlideShow(row.original?._id!, "images")}
              >
                <div className="bg-blue-600 rounded group relative w-max flex items-center gap-2 mt-2">
                  <BsImages className="w-5 h-5" />
                  Xem hình
                </div>
                {userAuth?.role === 3 && (
                  <div
                    className="bg-blue-600 rounded flex items-center mt-2 gap-2 text-white group cursor-pointer "
                    onClick={() => openFormUpdateImagesForm(row.original?._id!)}
                  >
                    <BsFillCloudUploadFill className="w-5 h-5 cursor-pointer" />
                    Upload
                  </div>
                )}
              </div>
            )}
          </>
        ),
      },
      {
        id: "Hình ảnh MNR",
        header: "Hình ảnh MNR",

        cell: ({ row }) => (
          <>
            {loadingImagesMNR && containerUpdate?._id === row.original._id ? (
              <LoadingComponent />
            ) : !row.original?.hasImagesAfterRepair &&
              !row.original?.imagesAfterRepair?.length ? (
              <div className="flex items-center justify-center gap-9">
                <div>Chưa có hình</div>
                {userAuth?.role === 3 && (
                  <div
                    className="bg-blue-600 rounded flex items-center mt-2 gap-2 text-white group cursor-pointer "
                    onClick={() =>
                      openFormUpdateImagesAfterRepairForm(row.original?._id!)
                    }
                  >
                    <BsFillCloudUploadFill className="w-5 h-5 cursor-pointer" />
                    Upload
                  </div>
                )}
              </div>
            ) : (
              <div
                className="flex gap-4 divide-x text-white group cursor-pointer"
                onClick={() =>
                  openSlideShow(row.original?._id!, "imagesAfterRepair")
                }
              >
                <div className="bg-blue-600 mt-2 rounded group relative w-max flex items-center gap-2">
                  <BsImages className="w-5 h-5 " />
                  Xem hình
                </div>
                {userAuth?.role === 3 && (
                  <div
                    className="bg-blue-600 rounded flex items-center mt-2 gap-2 text-white group cursor-pointer "
                    onClick={() =>
                      openFormUpdateImagesAfterRepairForm(row.original?._id!)
                    }
                  >
                    <BsFillCloudUploadFill className="w-5 h-5 cursor-pointer" />
                    Upload
                  </div>
                )}
              </div>
            )}
          </>
        ),
      },
      {
        id: "Hình Gate Out",
        header: "Hình Gate Out",

        cell: ({ row }) => (
          <>
            {loadingImagesGateOut &&
            containerUpdate?._id === row.original._id ? (
              <LoadingComponent />
            ) : !row.original?.hasImagesGateOut &&
              !row.original?.imagesGateOut?.length ? (
              <div className="flex items-center justify-center gap-9">
                <div>Chưa có hình</div>
                {userAuth?.role === 3 && (
                  <div
                    className="bg-blue-600 rounded flex items-center mt-2 gap-2 text-white group cursor-pointer "
                    onClick={() =>
                      openFormUpdateImagesGateOutForm(row.original?._id!)
                    }
                  >
                    <BsFillCloudUploadFill className="w-5 h-5 cursor-pointer" />
                    Upload
                  </div>
                )}
              </div>
            ) : (
              <div
                className="flex gap-4 divide-x text-white group cursor-pointer"
                onClick={() =>
                  openSlideShow(row.original?._id!, "imagesGateOut")
                }
              >
                <div className="bg-blue-600 rounded group relative w-max flex items-center gap-2 mt-2">
                  <BsImages className="w-5 h-5 " />
                  Xem hình
                </div>
                {userAuth?.role === 3 && (
                  <div
                    className="bg-blue-600 rounded flex items-center mt-2 gap-2 text-white group cursor-pointer "
                    onClick={() =>
                      openFormUpdateImagesGateOutForm(row.original?._id!)
                    }
                  >
                    <BsFillCloudUploadFill className="w-5 h-5 cursor-pointer" />
                    Upload
                  </div>
                )}
              </div>
            )}
          </>
        ),
      },
      {
        accessorKey: "Trạng thái container",
        header: () => <span>Trạng thái container</span>,
        accessorFn: (row) => containerStatusHelper(row?.contStatus),
        cell: (info) => info.getValue(),
        filterFn: "includesStringSensitive", //note: normal non-fuzzy filter column
      },
      {
        accessorKey: "Phân loại container",
        header: () => <span>Phân loại container</span>,
        accessorFn: (row) =>
          row.listLifecycle !== undefined &&
          row.listLifecycle.at(-1)?.alreadyGateIn &&
          !row.listLifecycle.at(-1)?.alreadyGateOut &&
          row.listLifecycle.at(-1)?.noteGateIn,
        cell: (info) => info.getValue(),
        filterFn: "includesStringSensitive", //note: normal non-fuzzy filter column
      },
      {
        accessorKey: "VAT",
        header: () => <span>VAT</span>,
        accessorFn: (row) => row.VAT + "%",
        cell: (info) => info.getValue(),
        filterFn: "includesStringSensitive", //note: normal non-fuzzy filter column
      },
      {
        accessorKey: "Giá bán ra",
        header: () => <span>Giá bán ra</span>,
        accessorFn: (row) =>
          new Intl.NumberFormat("de-DE").format(
            parseInt(!row?.sellingPrice ? "0" : row?.sellingPrice)
          ),
        cell: (info) => info.getValue(),
        filterFn: "includesStringSensitive", //note: normal non-fuzzy filter column
      },
      {
        id: "Hành động",
        header: "Hành động",

        cell: ({ row }) => (
          <>
            {row.original.contStatus !== -1 && (
              <>
                <div
                  onClick={() => handleOpenFormSellCont(row.original?._id)}
                  className="inline-flex justify-center border-2 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-green-600 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                >
                  <AiFillDollarCircle
                    className="ml-1 mr-2 h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                  <span className="text-white">Bán container</span>
                </div>
                <div
                  onClick={() => handleOpenFormRent(row.original._id)}
                  className="inline-flex justify-center border-2 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-500 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                >
                  <GoContainer
                    className="ml-1 mr-2 h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                  <span className="text-white">Cho thuê container</span>
                </div>
                {row.original.serviceSupplier?.label !== "CTA" &&
                  userAuth?.role === 3 && (
                    <div
                      onClick={() =>
                        handleOpenFormUpdateGateIn(row.original?._id)
                      }
                      className="inline-flex justify-center border-2 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-500 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                    >
                      <FaFileInvoiceDollar
                        className="ml-1 mr-2 h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      <span className="text-white">Xác nhận Gate In</span>
                    </div>
                  )}
              </>
            )}
          </>
        ),
      },
    ],
    [selected, dataContainerInStore]
  );

  const [data, setData] = React.useState<ContainerApiProps[]>(
    () => dataContainerInStore
  );

  const refreshData = () => setData((_old) => dataContainerInStore); //stress test

  React.useEffect(() => {
    refreshData();
  }, [dataContainerInStore]);

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter, //define as a filter function that can be used in column definitions
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: "fuzzy", //apply fuzzy filter to the global filter (most common use case for fuzzy filter)
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  const handleOpenFormSellMany = () => {
    openFormSellMany();
  };

  //TODO end handle
  return (
    <div className="p-2 overflow-hidden overflow-x-auto">
      <div className="flex">
        <div className="mb-4">
          <DebouncedInput
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
            className="p-2 font-lg shadow border border-block"
            placeholder="Tìm theo tất cả điều kiện..."
          />
        </div>

        {selected.length > 1 && (
          <div>
            <div
              onClick={() => handleOpenFormSellMany()}
              className="mt-4 lg:mt-0 ml-4 inline-flex flex-shrink-0 justify-center px-4 py-4 border-2 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-green-600 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            >
              <AiFillDollarCircle
                className="-ml-1 mr-2 h-5 w-5 text-white"
                aria-hidden="true"
              />
              <span className="text-white">Bán tất cả</span>
            </div>
          </div>
        )}

        <div>
          <button
            onClick={() => refreshData()}
            className="shadow border border-block ml-3 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Cập nhật dữ liệu mới nhất
          </button>
        </div>
      </div>
      <div className="flex flex-wrap shadow border bg-gray-100">
        <div className="px-1 border-b shadow border border-r">
          <label>
            <input
              {...{
                type: "checkbox",
                checked: table.getIsAllColumnsVisible(),
                onChange: table.getToggleAllColumnsVisibilityHandler(),
              }}
            />{" "}
            Hiển thị tất cả
          </label>
        </div>
        {table.getAllLeafColumns().map((column) => {
          return (
            <div key={column.id} className="p-1 w-44">
              <label>
                <input
                  {...{
                    type: "checkbox",
                    checked: column.getIsVisible(),
                    onChange: column.getToggleVisibilityHandler(),
                  }}
                />{" "}
                {column.id}
              </label>
            </div>
          );
        })}
      </div>

      <div className="h-2" />
      <table className=" shadow border mb-4">
        <thead className="bg-gray-50 ">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    className="whitespace-nowrap"
                    key={header.id}
                    colSpan={header.colSpan}
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer select-none"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: " 🔼",
                            desc: " 🔽",
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} />
                          </div>
                        ) : null}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      key={cell.id}
                      className="overflow-hidden whitespace-nowrap text-ellipsis border border-slate-500"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="h-2" />
      <div className="flex items-center gap-2">
        {/* <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {"<<"}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {"<"}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {">"}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {">>"}
        </button>
        <span className="flex items-center gap-1">
          <div>Trang</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </strong>
        </span>
        <span className="flex items-center gap-1">
          | Đi đến trang:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
            className="border p-1 rounded w-16"
          />
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            // table.setPageSize(Number(e.target.value));
            table.setPageSize(Number(10000));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Hiện {pageSize}
            </option>
          ))}
        </select> */}
        {/* <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[
            { value: 10, label: "10" },
            // { value: 20, label: "20" },
            // { value: 50, label: "50" },
            { value: 10000, label: "toàn bộ" },
          ].map((pageSize) => (
            <option key={pageSize.value} value={pageSize.value}>
              Hiện {pageSize.label}
            </option>
          ))}
        </select> */}
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={(e) => {
            table.setPageSize(Number(10000));
          }}
        >
          Xem tất cả
        </button>
      </div>
      {/* <div className="text-red-500 font-bold">{table.getPrePaginationRowModel().rows.length} Kết quả</div> */}
      {/* <div>
        <button onClick={() => rerender()}>Force Rerender</button>
      </div>
      <div>
        <button onClick={() => refreshData()}>Refresh Data</button>
      </div>
      <pre>
        {JSON.stringify(
          {
            columnFilters: table.getState().columnFilters,
            globalFilter: table.getState().globalFilter,
          },
          null,
          2
        )}
      </pre> */}
    </div>
  );
};

function Filter({ column }: { column: Column<any, unknown> }) {
  const columnFilterValue = column.getFilterValue();

  return (
    <DebouncedInput
      type="text"
      value={(columnFilterValue ?? "") as string}
      onChange={(value) => column.setFilterValue(value)}
      placeholder={`Search...`}
      className="w-36 border shadow rounded"
    />
  );
}

// A typical debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}
