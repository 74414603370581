import { useEffect, useState } from "react";
//redux
import { useSelector } from "react-redux";
import {
  addAction,
  getAllAction,
  getByIdAction,
  updateAction,
  selectContainer,
  clearAContainerAction,
  updateImagesAction,
  updateImagesAfterRepairAction,
  updateImagesGateOutAction,
  sellContainerAction,
  sellManyAction,
  updateGateInAction,
  clearContainersAction,
  getAllContainerStorageWithoutLimitAction,
  updateContainerRentAction,
} from "../../../redux/slices/Container/containerSlice";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { openForm, closeForm } from "../../../redux/slices/formSlices";
import {
  getAllNotificationAction,
  selectUser,
} from "../../../redux/slices/User/UserSlice";
import {
  clearSelectionAction,
  selectSelects,
} from "../../../redux/slices/SelectCheckbox/SelectCheckboxSlice";
import {
  getAllWithoutLimitAction as getAllServiceSupplierWithoutLimitAction,
  selectServiceSuppliers,
} from "../../../redux/slices/ServiceSupplier/ServiceSupplierSlice";
import {
  getAllWithoutLimitAction as getAllSupplierWithoutLimitAction,
  selectSupplier,
} from "../../../redux/slices/Supplier/SupplierSlice";
//icons
import { HiOutlinePlusSm } from "react-icons/hi";
//libraries
import ReactPaginate from "react-paginate";
import { Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { Socket } from "socket.io-client";
//types
import { ContainerApiParams, ContainerApiProps } from "../../../types/apiType";
import { ImageApiProps } from "../../../types";
//utils
//components
import ToastSuccess from "../../../components/Toast/success";
import ToastError, {
  ToastError3Seconds,
} from "../../../components/Toast/error";
import LogoutConfirmation from "../../../components/Logout/LogoutConfirmation";
import TableHeader from "../../../components/TableHeader";
import { Slider } from "../../../components/Slider";
//hooks
import useSocketJoinRoom from "../../../hooks/useSocketJoinRoom";
import useSocketEventHandler from "../../../hooks/useSocketHandler";
//constants
import { headersContainerUser } from "../../../constants/headersTable";
//file link
import { ListItem } from "./ListItem";
import { Search } from "./Search";
import { FormUpdateImages } from "../FormUpdate/FormUpdateImages";
import { Form } from "../FormUpdate/Form";
import { FormUpdateImageAfterRepair } from "../FormUpdate/FormUpdateImageAfterRepair";
import { FormUpdateImagesGateOut } from "../FormUpdate/FormUpdateImagesGateOut";
import { FormUpdateSellCont } from "../FormUpdate/FormUpdateSellCont";
import { FormSellMany } from "../FormUpdate/FormSellMany";
import { FormUpdateGateInOut } from "../FormUpdate/FormUpdateGateInOut";
import {
  updateMessageManyContainersNotification,
  updateMessageNotification,
} from "../../../constants/messageNotification";
import { paramsNotification } from "../../../utils/paramsHelper";
import {
  getCounterLatestAction,
  selectCounter,
} from "../../../redux/slices/Counter/CounterSlice";
import LoadingComponent from "../../../components/LoadingComponent";
import NotFoundLoading from "../../../components/LoadingComponent/NotFoundLoading";
import { TableContainerUser } from "../TableContainerUser";
import { FormRent } from "../../Container/FormUpdate/FormRent";

interface SocketProps {
  socket: Socket;
}
export const ListUserContainer = ({ socket }: SocketProps) => {
  const [IDSellCont, setIDSellCont] = useState<string>();
  useSocketJoinRoom(socket, "container");
  useSocketJoinRoom(socket, "invoice");

  const handleUpdateDataSocket = (msg: any) => {
    dispatch(getAllAction(params));
    dispatch(getAllNotificationAction(paramsNotification));
    if (IDSellCont !== undefined) {
      dispatch(getByIdAction(IDSellCont));
      dispatch(getCounterLatestAction());
    }
  };

  // Handle "add-receive" and "update-receive" events with event handlers
  useSocketEventHandler(socket, "update-receive", handleUpdateDataSocket);
  useSocketEventHandler(socket, "add-receive", handleUpdateDataSocket);
  //redux
  const dispatch = useAppDispatch();
  //navigation
  const navigate = useNavigate();

  const [formStatusState, setFormStatusState] = useState(false);
  const title = "Quản lý container";
  const [isUpdate, setIsUpdate] = useState(false);
  const [slideStatusState, setSlideStatusState] = useState(false);
  const [isUpdateImages, setIsUpdateImages] = useState(false);
  const [isUpdateImagesAfterRepair, setIsUpdateImagesAfterRepair] =
    useState(false);
  const [isUpdateImagesGateOut, setIsUpdateImagesGateOut] = useState(false);
  const [isUpdateSellCont, setIsUpdateSellCont] = useState(false);
  const [isUpdateGateIn, setIsUpdateGateIn] = useState(false);
  const [isGateIn, setIsGateIn] = useState(false);
  const [isSellMany, setIsSellMany] = useState(false);
  const [isRent, setIsRent] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [keyword, setKeyword] = useState("");
  const [contStatus, setContStatus] = useState(0);
  const [currentNameField, setCurrentNameField] = useState("");
  const [serviceSupplierId, setServiceSupplierId] = useState<
    string | undefined
  >(undefined);
  const [supplierId, setSupplierId] = useState<string | undefined>(undefined);
  const [size, setSize] = useState<string | undefined>(undefined);
  const [searchKey, setSearchKey] = useState(0);
  const [formStatusRent, setFormStatusRent] = useState(false);
  //set offset
  let offset = currentPage - 1;
  //set params
  // const params: ContainerApiParams = {
  //   keyword: keyword,
  //   offset: offset,
  //   limit: limit,
  //   contStatus: contStatus,
  //   serviceSupplierId: serviceSupplierId,
  //   supplierId: supplierId,
  //   size: size,
  // };

  const [params] = useState<ContainerApiParams>({
    keyword: keyword,
    offset: offset,
    limit: limit,
    contStatus: contStatus,
    serviceSupplierId: serviceSupplierId,
    supplierId: supplierId,
    size: size,
  });

  const getData = async () => {
    document.title = title;
    // dispatch(getAllAction(params));
    await dispatch(getAllContainerStorageWithoutLimitAction());
  };

  useEffect(() => {
    dispatch(clearContainersAction());
    dispatch(clearAContainerAction());
    dispatch(getAllNotificationAction(paramsNotification));
    getData();
    // dispatch(getAllServiceSupplierWithoutLimitAction(params));
    // dispatch(getAllSupplierWithoutLimitAction(params));
    dispatch(clearSelectionAction());
  }, []);

  //get data from redux
  const containerData = useSelector(selectContainer);
  const {
    data,
    loading,
    serverError,
    container,
    appError,
    loadingImagesGateIn,
    loadingImagesNMR,
    loadingImagesGateOut,
  } = containerData;
  const userData = useSelector(selectUser);
  const { userAuth } = userData;
  const selectData = useSelector(selectSelects);
  const { selected } = selectData;
  const supplierInStore = useSelector(selectSupplier);
  const { data: supplierData } = supplierInStore;
  const serviceSupplierInStore = useSelector(selectServiceSuppliers);
  const { data: serviceSupplierData } = serviceSupplierInStore;
  const { counter } = useSelector(selectCounter);
  // ==== paging ==== //
  const handlePageClick = (event: { selected: number }) => {
    params.offset = event.selected * limit;
    dispatch(clearSelectionAction());
    getData();
  };
  // ==== paging END ==== //
  // search data
  const handleSearch = (
    keyword: string,
    numberOfLimit: number,
    supplierId: string | undefined,
    serviceSupplierId: string | undefined,
    size: string | undefined
  ) => {
    setKeyword(keyword);
    setLimit(numberOfLimit);
    supplierId?.charAt(0) === "-"
      ? setSupplierId(undefined)
      : setSupplierId(supplierId);
    serviceSupplierId?.charAt(0) === "-"
      ? setServiceSupplierId(undefined)
      : setServiceSupplierId(serviceSupplierId);

    if (size?.charAt(0) === "-") {
      setSize(undefined);
      params.size = undefined;
    } else {
      setSize(size);
      params.size = size;
    }
    if (serviceSupplierId?.charAt(0) === "-") {
      setServiceSupplierId(undefined);
      params.serviceSupplierId = undefined;
    } else {
      setServiceSupplierId(serviceSupplierId);
      params.serviceSupplierId = serviceSupplierId;
    }
    if (supplierId?.charAt(0) === "-") {
      setSupplierId(undefined);
      params.supplierId = undefined;
    } else {
      setSupplierId(supplierId);
      params.supplierId = supplierId;
    }
    params.keyword = keyword;
    params.limit = numberOfLimit;

    params.offset = 0;

    setSearchKey((prevKey) => prevKey + 1);
    getData();
  };

  const handleOpenFormSellMany = () => {
    setIsSellMany(true);
    const action = openForm();
    dispatch(action);
  };

  // open create form event
  const handleOpenFormAdd = () => {
    setFormStatusState(true);
    const action = openForm();
    dispatch(action);
  };

  // create data event
  const handleAddData = async (data: ContainerApiProps) => {
    setFormStatusState(false);

    const action = await dispatch(addAction(data));

    if (addAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.data.containerNumber,
          action.payload.data.supplier?.label,
          action.payload.data.serviceSupplier?.label,
          "gate in 1 container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError3Seconds(action.payload as string, serverError);
    }
  };

  const handleUpdateData = async (
    _id: string | undefined,
    data: ContainerApiProps
  ) => {
    setFormStatusState(false);
    setIsUpdate(false);
    const dataUpdate = {
      _id,
      ...data,
    };
    const action = await dispatch(updateAction(dataUpdate));

    if (updateAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier?.label,
          action.payload.newData.serviceSupplier?.label,
          "cập nhật 1 container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  // open update form event
  const handleOpenFormUpdate = (id: string) => {
    setFormStatusState(true);
    const action = openForm();
    dispatch(action);
    setIsUpdate(true);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  // open update form rent
  const handleOpenFormRent = (id: string) => {
    setFormStatusRent(true);
    const action = openForm();
    dispatch(action);
    setIsRent(true);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleOpenSlide = (id: string, currentNameField: string) => {
    setSlideStatusState(true);
    const action = openForm();
    dispatch(action);
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
    // Set the currentNameField to the selected nameField
    setCurrentNameField(currentNameField);
  };

  // close form event
  const handleCloseForm = () => {
    setFormStatusState(false);
    const action = closeForm();
    dispatch(action);
    setIsUpdate(false);
  };

  const handleCloseFormRent = () => {
    setFormStatusRent(false);
    const action = closeForm();
    dispatch(action);
    setIsRent(false);
  };

  const handleCloseSlide = () => {
    setSlideStatusState(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleOpenFormUpdateImages = (id: string) => {
    // setFormStatusState(true);
    setIsUpdateImages(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleCloseUpdateImages = () => {
    setIsUpdateImages(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleCloseSellMany = () => {
    setIsSellMany(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleOpenFormUpdateImagesAfterRepair = (id: string) => {
    // setFormStatusState(true);
    setIsUpdateImagesAfterRepair(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleOpenFormUpdateImagesGateOut = (id: string) => {
    // setFormStatusState(true);
    setIsUpdateImagesGateOut(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleOpenFormSellCont = (id: string) => {
    // setFormStatusState(true);
    setIsUpdateSellCont(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    //set ID Sell Cont to apply socket action
    setIDSellCont(id);
    dispatch(getByIdAction(id));
  };

  const handleOpenFormGateIn = (id: string) => {
    setIsUpdateGateIn(true);
    setIsGateIn(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleCloseUpdateImagesAfterRepair = () => {
    setIsUpdateImagesAfterRepair(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleCloseUpdateImagesGateOut = () => {
    setIsUpdateImagesGateOut(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleCloseSellCont = () => {
    setIsUpdateSellCont(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleCloseGateIn = () => {
    setIsUpdateGateIn(false);
    setIsGateIn(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleUpdateSellContData = async (
    _id: string | undefined,
    data: ContainerApiProps
  ) => {
    setIsUpdateSellCont(false);
    const dataUpdate = {
      _id,
      ...data,
    };
    const action = await dispatch(sellContainerAction(dataUpdate));

    if (sellContainerAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        counter: true,
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier.label,
          action.payload.newData.serviceSupplier.label,
          parseInt(action.payload.newData.depositAmount) > 0
            ? "cọc 1 container"
            : "bán 1 container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleUpdateGateInData = async (
    _id: string | undefined,
    data: ContainerApiProps
  ) => {
    setIsUpdateGateIn(false);
    setIsGateIn(false);
    const dataUpdate = {
      _id,
      ...data,
    };
    const action = await dispatch(updateGateInAction(dataUpdate));

    if (updateGateInAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier.label,
          action.payload.newData.serviceSupplier.label,
          "gate in 1 container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleUpdateGateOutData = async (
    _id: string | undefined,
    data: ContainerApiProps
  ) => {
    setIsUpdateGateIn(false);
    setIsGateIn(false);
    const dataUpdate = {
      _id,
      ...data,
    };
    const action = await dispatch(updateGateInAction(dataUpdate));

    if (updateGateInAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: "ok",
      });
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleUpdateImagesData = async (
    _id: string | undefined,
    data: FormData
  ) => {
    setIsUpdateImages(false);
    const dataUpdate: ImageApiProps = {
      _id,
      formData: data,
    };
    const action = await dispatch(updateImagesAction(dataUpdate));

    if (updateImagesAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier.label,
          action.payload.newData.serviceSupplier.label,
          "cập nhật hình ảnh container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleUpdateImagesAfterRepairData = async (
    _id: string | undefined,
    data: FormData
  ) => {
    setIsUpdateImagesAfterRepair(false);
    const dataUpdate: ImageApiProps = {
      _id,
      formData: data,
    };
    const action = await dispatch(updateImagesAfterRepairAction(dataUpdate));

    if (updateImagesAfterRepairAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier.label,
          action.payload.newData.serviceSupplier.label,
          "cập nhật hình ảnh sau sửa chữa container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleSellManyContData = async (data: ContainerApiProps) => {
    setIsSellMany(false);
    dispatch(clearSelectionAction());
    const dataUpdateMany: ContainerApiProps = {
      containerIds: selected,
      buyer: data.buyer,
      contStatus: data.contStatus,
      depositAmount: data.depositAmount,
      depositDuration: data.depositDuration,
      actualSellingPrice: data.actualSellingPrice,
      storageTime: data.storageTime,
      saleBookingNumber: data.saleBookingNumber,
    };
    const action = await dispatch(sellManyAction(dataUpdateMany));

    if (sellManyAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        counter: true,
        updateData: updateMessageManyContainersNotification(
          userAuth?.currentName,
          action.payload.listId,
          action.payload.isDeposit ? "cọc" : "bán"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleUpdateImagesGateOutData = async (
    _id: string | undefined,
    data: FormData
  ) => {
    setIsUpdateImagesGateOut(false);
    const dataUpdate: ImageApiProps = {
      _id,
      formData: data,
    };
    const action = await dispatch(updateImagesGateOutAction(dataUpdate));

    if (updateImagesGateOutAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier.label,
          action.payload.newData.serviceSupplier.label,
          "cập nhật hình ảnh gate out container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleRentData = async (
    _id: string | undefined,
    data: ContainerApiProps
  ) => {
    setFormStatusRent(false);
    setIsRent(false);
    const dataUpdate = {
      _id,
      ...data,
    };
    const action = await dispatch(updateContainerRentAction(dataUpdate));

    if (updateContainerRentAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier?.label,
          action.payload.newData.serviceSupplier?.label,
          "cho thuê 1 container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError3Seconds(action.payload as string, serverError);
    }
  };

  // check show form
  const displayForm = () => {
    if (formStatusState) {
      return (
        <Form
          closeForm={handleCloseForm}
          isUpdate={isUpdate}
          addData={handleAddData}
          updateData={handleUpdateData}
          dataUpdate={container}
        />
      );
    } else if (isUpdateImages) {
      return (
        <FormUpdateImages
          closeForm={handleCloseUpdateImages}
          updateData={handleUpdateImagesData}
          dataUpdate={container}
        />
      );
    } else if (isUpdateImagesAfterRepair) {
      return (
        <FormUpdateImageAfterRepair
          closeForm={handleCloseUpdateImagesAfterRepair}
          updateData={handleUpdateImagesAfterRepairData}
          dataUpdate={container}
        />
      );
    } else if (isUpdateImagesGateOut) {
      return (
        <FormUpdateImagesGateOut
          closeForm={handleCloseUpdateImagesGateOut}
          updateData={handleUpdateImagesGateOutData}
          dataUpdate={container}
        />
      );
    } else if (isUpdateSellCont) {
      return (
        <FormUpdateSellCont
          closeForm={handleCloseSellCont}
          updateData={handleUpdateSellContData}
          dataUpdate={container}
          counter={counter}
        />
      );
    } else if (isSellMany) {
      return (
        <FormSellMany
          closeForm={handleCloseSellMany}
          updateData={handleSellManyContData}
          counter={counter}
        />
      );
    } else if (isUpdateGateIn) {
      return (
        <FormUpdateGateInOut
          closeForm={handleCloseGateIn}
          updateData={handleUpdateGateInData}
          updateGateOut={handleUpdateGateOutData}
          dataUpdate={container}
          isGateIn={isGateIn}
        />
      );
    } else if (formStatusRent) {
      return (
        <FormRent
          closeForm={handleCloseFormRent}
          isRent={isRent}
          updateData={handleRentData}
          dataUpdate={container}
        />
      );
    }
  };

  if (
    appError?.statusCode === 500 &&
    appError.message?.includes("Token đã hết hạn")
  ) {
    LogoutConfirmation(
      navigate,
      dispatch,
      "Đã hết phiên làm việc, vui lòng đăng nhập lại?"
    );
    // Return a placeholder or loading component, for example
    return <div>Loading...</div>;
  }

  const generateTransition = (show: boolean) => (
    <Transition
      show={show}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {displayForm()}
    </Transition>
  );

  return (
    <>
      <div className="bg-blue-100 h-screen ">
        {generateTransition(formStatusState)}
        {generateTransition(formStatusRent)}
        {generateTransition(isUpdateImages)}
        {generateTransition(isUpdateImagesAfterRepair)}
        {generateTransition(isUpdateImagesGateOut)}
        {generateTransition(isUpdateSellCont)}
        {generateTransition(isSellMany)}
        {generateTransition(isUpdateGateIn)}
        {slideStatusState && (
          <div className="z-10 max-w-[540px] h-[620px] m-auto py-16 px-4 group w-3/4 fixed left-1/2 ml-[-37.5%] min-[2000px]:ml-[-30%] top-16">
            <Slider
              closeForm={handleCloseSlide}
              isBigger={false}
              currentNameField={currentNameField}
            />
          </div>
        )}
        <div className="flex flex-col bg-slate-50 mx-2 rounded-2xl p-4 ">
          <div className="flex flex-row ml-2">
            <div className="absolute left-5 w-1 bg-blue-400 h-14"></div>
            <div className="flex-none flex-shrink-0">
              <p className="font-sans font-semibold text-3xl">{title}</p>
              <p className="font-sans text-sm italic">{title} Conta</p>
            </div>

            {/* Add button */}
            {userAuth?.role === 3 && (
              <div className="flex items-center grow justify-end flex-shrink-0">
                <HiOutlinePlusSm
                  onClick={() => handleOpenFormAdd()}
                  className="text-4xl bg-blue-600 rounded-full text-white hover:bg-blue-500 cursor-pointer "
                />
              </div>
            )}
          </div>

          {/* <Search
            handleSearch={handleSearch}
            openFormSellMany={handleOpenFormSellMany}
            dataServiceSupplier={serviceSupplierData}
            dataSupplier={supplierData}
          /> */}

          {/*<div className="overflow-hidden">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200 table-auto mb-4">
                      <thead className="bg-gray-50">
                        <TableHeader titles={headersContainerUser} />
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td colSpan={4} className="text-center pl-4 pt-4">
                              <LoadingComponent />
                            </td>
                          </tr>
                        ) : (data && data?.length <= 0) || data == null ? (
                          <tr>
                            <td colSpan={4} className="text-center pl-4 pt-4">
                              <NotFoundLoading />
                            </td>
                          </tr>
                        ) : (
                          <ListItem
                            data={data}
                            openFormUpdate={(id) => handleOpenFormUpdate(id)}
                            openFormUpdateImages={(id) =>
                              handleOpenFormUpdateImages(id)
                            }
                            openFormUpdateImagesAfterRepair={(id) =>
                              handleOpenFormUpdateImagesAfterRepair(id)
                            }
                            openFormUpdateImagesGateOut={(id) =>
                              handleOpenFormUpdateImagesGateOut(id)
                            }
                            socket={socket}
                            userAuth={userAuth}
                            openSlide={(imageId, currentNameField) =>
                              handleOpenSlide(imageId, currentNameField)
                            }
                            openFormSellCont={(id) =>
                              handleOpenFormSellCont(id)
                            }
                            openFormGateIn={(id) => handleOpenFormGateIn(id)}
                          />
                        )}
                      </tbody>
                    </table>
                    {totalPage > 1 ? (
                      <ReactPaginate
                        key={searchKey}
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={totalPage} 
                        previousLabel="<"
                        pageClassName="inline-block px-4 py-1 rounded-lg mx-1 bg-gray-200 text-gray-800"
                        pageLinkClassName="block w-full h-full"
                        previousClassName="inline-block px-2 py-1 rounded-lg mx-1 bg-gray-200 text-gray-800"
                        previousLinkClassName="block w-full h-full"
                        nextClassName="inline-block px-2 py-1 rounded-lg mx-1 bg-gray-200 text-gray-800"
                        nextLinkClassName="block w-full h-full"
                        breakLabel="..."
                        breakClassName="inline-block px-2 py-1 mx-1 text-gray-600"
                        breakLinkClassName=""
                        containerClassName="flex justify-start my-4 "
                        activeClassName="background-color-base-paging text-white"
                        disabledClassName="opacity-50 cursor-not-allowed"
                        renderOnZeroPageCount={null}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          */}
          {loading && data && data?.length <= 0 ? (
            <div className="text-center pl-4 pt-4">
              <LoadingComponent />
            </div>
          ) : (
            <TableContainerUser
              dataContainer={data}
              openFormUpdate={(id: string) => handleOpenFormUpdate(id)}
              openFormRent={(id: string) => handleOpenFormRent(id)}
              containerUpdate={container}
              loadingImagesGateIn={loadingImagesGateIn}
              loadingImagesMNR={loadingImagesNMR}
              loadingImagesGateOut={loadingImagesGateOut}
              openFormUpdateImages={(id) => handleOpenFormUpdateImages(id)}
              openFormUpdateImagesAfterRepair={(id) =>
                handleOpenFormUpdateImagesAfterRepair(id)
              }
              openFormUpdateImagesGateOut={(id) =>
                handleOpenFormUpdateImagesGateOut(id)
              }
              socket={socket}
              userAuth={userAuth}
              openSlide={(imageId, currentNameField) =>
                handleOpenSlide(imageId, currentNameField)
              }
              openFormSellCont={(id) => handleOpenFormSellCont(id)}
              openFormGateIn={(id) => handleOpenFormGateIn(id)}
              openFormSellMany={handleOpenFormSellMany}
            />
          )}
        </div>
      </div>
    </>
  );
};
