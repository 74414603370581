import { useEffect, useState } from "react";
//redux
import { useSelector } from "react-redux";
import {
  addAction,
  getByIdAction,
  updateAction,
  selectContainer,
  clearAContainerAction,
  updateImagesAction,
  updateImagesAfterRepairAction,
  updateImagesGateOutAction,
  sellContainerAction,
  updateManyAction,
  sellManyAction,
  updateContNumberAction,
  updateGateInAction,
  updateGateOutAction,
  getAllContainerStorageWithoutLimitAction,
  updateContainerRentAction,
} from "../../../redux/slices/Container/containerSlice";
import { getAllNotificationAction } from "../../../redux/slices/User/UserSlice";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { openForm, closeForm } from "../../../redux/slices/formSlices";
import { selectServiceSuppliers } from "../../../redux/slices/ServiceSupplier/ServiceSupplierSlice";
import { selectSupplier } from "../../../redux/slices/Supplier/SupplierSlice";
import { selectUser } from "../../../redux/slices/User/UserSlice";
import {
  clearSelectionAction,
  selectSelects,
} from "../../../redux/slices/SelectCheckbox/SelectCheckboxSlice";
//icons
import { HiOutlinePlusSm } from "react-icons/hi";
//libraries
import { Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { Socket } from "socket.io-client";
import Papa from "papaparse";
//types
import {
  ContainerApiParams,
  ContainerApiProps,
  UpdateContNumberProps,
} from "../../../types/apiType";
import { ImageApiProps } from "../../../types";
//utils
import { StringHelper } from "../../../utils/StringHelper";
//components
import ToastSuccess from "../../../components/Toast/success";
import ToastError, {
  ToastError3Seconds,
} from "../../../components/Toast/error";
import LogoutConfirmation from "../../../components/Logout/LogoutConfirmation";
import { Slider } from "../../../components/Slider";
//hooks
import useSocketJoinRoom from "../../../hooks/useSocketJoinRoom";
import useSocketEventHandler from "../../../hooks/useSocketHandler";
//constants
//file link
import { FormUpdateImages } from "../FormUpdate/FormUpdateImages";
import { Form } from "../FormUpdate/Form";
import { FormUpdateImageAfterRepair } from "../FormUpdate/FormUpdateImageAfterRepair";
import { FormUpdateImagesGateOut } from "../FormUpdate/FormUpdateImagesGateOut";
import { FormUpdateSellCont } from "../FormUpdate/FormUpdateSellCont";
import { FormUpdateMany } from "../FormUpdate/FormUpdateMany";
import { FormSellMany } from "../FormUpdate/FormSellMany";
import { FormUpdateContNumber } from "../FormUpdate/FormUpdateContNumber";
import { FormUpdateGateInOut } from "../../SellerContainer/FormUpdate/FormUpdateGateInOut";
import {
  updateMessageGateOutNotification,
  updateMessageManyContainersNotification,
  updateMessageNotification,
} from "../../../constants/messageNotification";
import { paramsNotification } from "../../../utils/paramsHelper";
import {
  getCounterLatestAction,
  selectCounter,
} from "../../../redux/slices/Counter/CounterSlice";
import LoadingComponent from "../../../components/LoadingComponent";
import { TableContainerAdmin } from "../TableContainerAdmin";
import { createSelector } from "@reduxjs/toolkit";
import { FormRent } from "../FormUpdate/FormRent";
import { gateInEIRPDF } from "../../../utils/PDFHelper/gateInEIRPDF";

const containerDataSelector = createSelector(
  selectContainer,
  (container) => container.data
);
export interface SocketProps {
  socket: Socket;
}

export const ListContainer = ({ socket }: SocketProps) => {
  const [IDSellCont, setIDSellCont] = useState<string>();
  useSocketJoinRoom(socket, "container");
  useSocketJoinRoom(socket, "invoice");

  const handleUpdateDataSocket = (msg: any) => {
    // dispatch(getAllAction(params));
    dispatch(getAllContainerStorageWithoutLimitAction());
    dispatch(getAllNotificationAction(paramsNotification));
    if (IDSellCont !== undefined) {
      dispatch(getByIdAction(IDSellCont));
      dispatch(getCounterLatestAction());
    }
  };

  // Handle "add-receive" and "update-receive" events with event handlers
  useSocketEventHandler(socket, "update-receive", handleUpdateDataSocket);
  useSocketEventHandler(socket, "add-receive", handleUpdateDataSocket);

  //redux
  const dispatch = useAppDispatch();
  //navigation
  const navigate = useNavigate();

  const [formStatusState, setFormStatusState] = useState(false);
  const [formStatusRent, setFormStatusRent] = useState(false);
  const title = "Quản lý container";
  const [isUpdate, setIsUpdate] = useState(false);
  const [isRent, setIsRent] = useState(false);
  const [slideStatusState, setSlideStatusState] = useState(false);
  const [isUpdateImages, setIsUpdateImages] = useState(false);
  const [isUpdateImagesAfterRepair, setIsUpdateImagesAfterRepair] =
    useState(false);
  const [isUpdateImagesGateOut, setIsUpdateImagesGateOut] = useState(false);
  const [isUpdateSellCont, setIsUpdateSellCont] = useState(false);
  const [isUpdateGateIn, setIsUpdateGateIn] = useState(false);
  const [isGateIn, setIsGateIn] = useState(false);
  const [isUpdateMany, setIsUpdateMany] = useState(false);
  const [isSellMany, setIsSellMany] = useState(false);
  const [isUpdateContNumber, setIsUpdateContNumber] = useState(false);
  const [limit, setLimit] = useState(5);
  const [keyword, setKeyword] = useState("");
  const [contStatus, setContStatus] = useState(0);
  const [serviceSupplierId, setServiceSupplierId] = useState<
    string | undefined
  >(undefined);
  const [supplierId, setSupplierId] = useState<string | undefined>(undefined);
  const [size, setSize] = useState<string | undefined>(undefined);
  const [currentNameField, setCurrentNameField] = useState("");
  const [searchKey, setSearchKey] = useState(0);
  //set offset
  let offset = 0;

  const [params, setParams] = useState<ContainerApiParams>({
    keyword: keyword,
    offset: offset,
    limit: limit,
    contStatus: contStatus,
    serviceSupplierId: serviceSupplierId,
    supplierId: supplierId,
    size: size,
  });

  //get data from redux
  const containerData = useSelector(selectContainer);
  const data = useSelector(containerDataSelector);

  const {
    // data,
    loading,
    loadingImagesGateIn,
    loadingImagesNMR,
    loadingImagesGateOut,
    serverError,
    container,
    appError,
  } = containerData;
  const userData = useSelector(selectUser);
  const { userAuth } = userData;
  const selectData = useSelector(selectSelects);
  const { selectedAllContainerRent } = selectData;
  const supplierInStore = useSelector(selectSupplier);
  const { data: supplierData } = supplierInStore;
  const serviceSupplierInStore = useSelector(selectServiceSuppliers);
  const { data: serviceSupplierData } = serviceSupplierInStore;
  const { counter } = useSelector(selectCounter);

  const getData = async () => {
    document.title = title;
    // console.log("keyword", params.keyword);
    // await dispatch(getAllAction(params));
    await dispatch(getAllContainerStorageWithoutLimitAction());
  };

  //TODO cal API
  useEffect(() => {
    // dispatch(clearContainersAction());
    dispatch(clearAContainerAction());
    dispatch(getAllNotificationAction(paramsNotification));
    getData();
    // dispatch(getAllServiceSupplierWithoutLimitAction(params));
    // dispatch(getAllSupplierWithoutLimitAction(params));
    dispatch(clearSelectionAction());
    dispatch(getCounterLatestAction());
  }, [dispatch]);
  //TODO END

  useEffect(() => {
    if (data) {
      // Perform any actions you need when containerData.data changes
      // console.log("New data received: ", data);
      // Update your component state or perform any side effects here
    }
  }, [data]);

  // ==== paging ==== //
  const handlePageClick = (event: { selected: number }) => {
    params.offset = event.selected * limit;
    dispatch(clearSelectionAction());
    getData();
  };
  // ==== paging END ==== //
  // search data
  const handleSearch = (
    keyword: string,
    numberOfLimit: number,
    supplierId: string | undefined,
    serviceSupplierId: string | undefined,
    size: string | undefined
  ) => {
    setKeyword(keyword);
    setLimit(numberOfLimit);
    supplierId?.charAt(0) === "-"
      ? setSupplierId(undefined)
      : setSupplierId(supplierId);
    serviceSupplierId?.charAt(0) === "-"
      ? setServiceSupplierId(undefined)
      : setServiceSupplierId(serviceSupplierId);

    if (size?.charAt(0) === "-") {
      setSize(undefined);
      params.size = undefined;
    } else {
      setSize(size);
      params.size = size;
    }
    if (serviceSupplierId?.charAt(0) === "-") {
      setServiceSupplierId(undefined);
      params.serviceSupplierId = undefined;
    } else {
      setServiceSupplierId(serviceSupplierId);
      params.serviceSupplierId = serviceSupplierId;
    }
    if (supplierId?.charAt(0) === "-") {
      setSupplierId(undefined);
      params.supplierId = undefined;
    } else {
      setSupplierId(supplierId);
      params.supplierId = supplierId;
    }
    params.keyword = keyword;
    params.limit = numberOfLimit;

    params.offset = 0;
    getData();
    setSearchKey((prevKey) => prevKey + 1);
  };

  const handleOpenFormUpdateMany = () => {
    setIsUpdateMany(true);
    const action = openForm();
    dispatch(action);
  };

  const handleOpenFormSellMany = () => {
    setIsSellMany(true);
    const action = openForm();
    dispatch(action);
  };

  const handleOpenFormUpdateNumberCont = () => {
    setIsUpdateContNumber(true);
    const action = openForm();
    dispatch(action);
  };

  // open create form event
  const handleOpenFormAdd = () => {
    setFormStatusState(true);
    const action = openForm();
    dispatch(action);
  };

  // create data event
  const handleAddData = async (data: ContainerApiProps) => {
    setFormStatusState(false);

    const action = await dispatch(addAction(data));

    if (addAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.data.containerNumber,
          action.payload.data.supplier?.label,
          action.payload.data.serviceSupplier?.label,
          "gate in 1 container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError3Seconds(action.payload as string, serverError);
    }
  };

  const handleUpdateData = async (
    _id: string | undefined,
    data: ContainerApiProps
  ) => {
    setFormStatusState(false);
    setIsUpdate(false);
    const dataUpdate = {
      _id,
      ...data,
    };
    const action = await dispatch(updateAction(dataUpdate));

    if (updateAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier?.label,
          action.payload.newData.serviceSupplier?.label,
          "cập nhật 1 container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError3Seconds(action.payload as string, serverError);
    }
  };

  const handleRentData = async (
    _id: string | undefined,
    data: ContainerApiProps
  ) => {
    setFormStatusRent(false);
    setIsRent(false);
    const dataUpdate = {
      _id,
      ...data,
    };
    const action = await dispatch(updateContainerRentAction(dataUpdate));

    if (updateContainerRentAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier?.label,
          action.payload.newData.serviceSupplier?.label,
          "cho thuê 1 container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError3Seconds(action.payload as string, serverError);
    }
  };

  // open update form event
  const handleOpenFormUpdate = (id: string) => {
    setFormStatusState(true);
    const action = openForm();
    dispatch(action);
    setIsUpdate(true);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  // open update form rent
  const handleOpenFormRent = (id: string) => {
    setFormStatusRent(true);
    const action = openForm();
    dispatch(action);
    setIsRent(true);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleOpenSlide = (id: string, currentNameField: string) => {
    setSlideStatusState(true);
    const action = openForm();
    dispatch(action);
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
    // Set the currentNameField to the selected nameField
    setCurrentNameField(currentNameField);
  };

  // close form event
  const handleCloseForm = () => {
    setFormStatusState(false);
    const action = closeForm();
    dispatch(action);
    setIsUpdate(false);
  };

  const handleCloseFormRent = () => {
    setFormStatusRent(false);
    const action = closeForm();
    dispatch(action);
    setIsRent(false);
  };

  const handleCloseSlide = () => {
    setSlideStatusState(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleOpenFormUpdateImages = (id: string) => {
    // setFormStatusState(true);
    setIsUpdateImages(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleCloseUpdateImages = () => {
    setIsUpdateImages(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleCloseUpdateMany = () => {
    setIsUpdateMany(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleCloseSellMany = () => {
    setIsSellMany(false);
    const action = closeForm();
    dispatch(action);
  };
  const handleCloseUpdateContNumber = () => {
    setIsUpdateContNumber(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleOpenFormUpdateImagesAfterRepair = (id: string) => {
    // setFormStatusState(true);
    setIsUpdateImagesAfterRepair(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleOpenFormUpdateImagesGateOut = (id: string) => {
    // setFormStatusState(true);
    setIsUpdateImagesGateOut(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleOpenFormSellCont = (id: string) => {
    // setFormStatusState(true);
    setIsUpdateSellCont(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    //set ID Sell Cont to apply socket action
    setIDSellCont(id);
    dispatch(getByIdAction(id));
  };

  const handleOpenFormGateIn = (id: string) => {
    setIsUpdateGateIn(true);
    setIsGateIn(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleCloseGateIn = () => {
    setIsUpdateGateIn(false);
    setIsGateIn(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleCloseUpdateImagesAfterRepair = () => {
    setIsUpdateImagesAfterRepair(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleCloseUpdateImagesGateOut = () => {
    setIsUpdateImagesGateOut(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleCloseSellCont = () => {
    setIsUpdateSellCont(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleUpdateGateInData = async (
    _id: string | undefined,
    data: ContainerApiProps,
    isExportPDF: boolean
  ) => {
    setIsUpdateGateIn(false);
    setIsGateIn(false);
    const dataUpdate = {
      _id,
      ...data,
      isExportPDF,
    };
    const action = await dispatch(updateGateInAction(dataUpdate));

    if (updateGateInAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier.label,
          action.payload.newData.serviceSupplier.label,
          "gate in 1 container"
        ),
      });
      if (!isExportPDF) {
        gateInEIRPDF(action.payload.newData, counter);
      }
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError3Seconds(action.payload as string, serverError);
    }
  };

  const handleUpdateGateOutData = async (
    _id: string | undefined,
    data: ContainerApiProps
  ) => {
    setIsUpdateGateIn(false);
    setIsGateIn(false);
    const dataUpdate = {
      _id,
      ...data,
    };
    const action = await dispatch(updateGateOutAction(dataUpdate));

    if (updateGateOutAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageGateOutNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier.label,
          action.payload.newData.serviceSupplier.label,
          "gate out 1 container",
          action.payload.newData.listLifecycle.at(-1)?.trunkNumberGateOut
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleUpdateSellContData = async (
    _id: string | undefined,
    data: ContainerApiProps
  ) => {
    setIsUpdateSellCont(false);
    const dataUpdate = {
      _id,
      ...data,
    };
    const action = await dispatch(sellContainerAction(dataUpdate));
    if (sellContainerAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        counter: true,
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier.label,
          action.payload.newData.serviceSupplier.label,
          parseInt(action.payload.newData.depositAmount) > 0
            ? "cọc 1 container"
            : "bán 1 container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleUpdateImagesData = async (
    _id: string | undefined,
    data: FormData
  ) => {
    setIsUpdateImages(false);
    const dataUpdate: ImageApiProps = {
      _id,
      formData: data,
    };
    const action = await dispatch(updateImagesAction(dataUpdate));

    if (updateImagesAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier?.label,
          action.payload.newData.serviceSupplier?.label,
          "cập nhật hình ảnh container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleUpdateImagesAfterRepairData = async (
    _id: string | undefined,
    data: FormData
  ) => {
    setIsUpdateImagesAfterRepair(false);
    const dataUpdate: ImageApiProps = {
      _id,
      formData: data,
    };
    const action = await dispatch(updateImagesAfterRepairAction(dataUpdate));

    if (updateImagesAfterRepairAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier.label,
          action.payload.newData.serviceSupplier.label,
          "cập nhật hình ảnh sau sửa chữa container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleUpdateManyContData = async (data: ContainerApiProps) => {
    setIsUpdateMany(false);
    dispatch(clearSelectionAction());
    const dataUpdateMany: ContainerApiProps = {
      containerIds: selectedAllContainerRent,
      sellingPrice: data.sellingPrice,
    };
    const action = await dispatch(updateManyAction(dataUpdateMany));

    if (updateManyAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageManyContainersNotification(
          userAuth?.currentName,
          action.payload.listId,
          "cập nhật giá"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleSellManyContData = async (data: ContainerApiProps) => {
    setIsSellMany(false);
    dispatch(clearSelectionAction());
    const dataUpdateMany: ContainerApiProps = {
      containerIds: selectedAllContainerRent,
      buyer: data.buyer,
      contStatus: data.contStatus,
      depositAmount: data.depositAmount,
      depositDuration: data.depositDuration,
      actualSellingPrice: data.actualSellingPrice,
      storageTime: data.storageTime,
      saleBookingNumber: data.saleBookingNumber,
    };
    const action = await dispatch(sellManyAction(dataUpdateMany));

    if (sellManyAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        counter: true,
        updateData: updateMessageManyContainersNotification(
          userAuth?.currentName,
          action.payload.listId,
          action.payload.isDeposit ? "cọc" : "bán"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleUpdateContNumberData = async (data: UpdateContNumberProps) => {
    setIsUpdateContNumber(false);
    dispatch(clearSelectionAction());
    const action = await dispatch(updateContNumberAction(data));

    if (updateContNumberAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageManyContainersNotification(
          userAuth?.currentName,
          action.payload.newData,
          "cập nhật số"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleUpdateImagesGateOutData = async (
    _id: string | undefined,
    data: FormData
  ) => {
    setIsUpdateImagesGateOut(false);
    const dataUpdate: ImageApiProps = {
      _id,
      formData: data,
    };
    const action = await dispatch(updateImagesGateOutAction(dataUpdate));
    if (updateImagesGateOutAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier.label,
          action.payload.newData.serviceSupplier.label,
          "cập nhật hình ảnh gate out container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  // check show form
  const displayForm = () => {
    if (formStatusState) {
      return (
        <Form
          closeForm={handleCloseForm}
          isUpdate={isUpdate}
          addData={handleAddData}
          updateData={handleUpdateData}
          dataUpdate={container}
        />
      );
    } else if (formStatusRent) {
      return (
        <FormRent
          closeForm={handleCloseFormRent}
          isRent={isRent}
          updateData={handleRentData}
          dataUpdate={container}
        />
      );
    } else if (isUpdateImages) {
      return (
        <FormUpdateImages
          closeForm={handleCloseUpdateImages}
          updateData={handleUpdateImagesData}
          dataUpdate={container}
        />
      );
    } else if (isUpdateImagesAfterRepair) {
      return (
        <FormUpdateImageAfterRepair
          closeForm={handleCloseUpdateImagesAfterRepair}
          updateData={handleUpdateImagesAfterRepairData}
          dataUpdate={container}
        />
      );
    } else if (isUpdateImagesGateOut) {
      return (
        <FormUpdateImagesGateOut
          closeForm={handleCloseUpdateImagesGateOut}
          updateData={handleUpdateImagesGateOutData}
          dataUpdate={container}
        />
      );
    } else if (isUpdateSellCont) {
      return (
        <FormUpdateSellCont
          closeForm={handleCloseSellCont}
          updateData={handleUpdateSellContData}
          dataUpdate={container}
          counter={counter}
        />
      );
    } else if (isUpdateMany) {
      return (
        <FormUpdateMany
          closeForm={handleCloseUpdateMany}
          updateData={handleUpdateManyContData}
        />
      );
    } else if (isSellMany) {
      return (
        <FormSellMany
          closeForm={handleCloseSellMany}
          updateData={handleSellManyContData}
          counter={counter}
          selectProps={selectData}
        />
      );
    } else if (isUpdateContNumber) {
      return (
        <FormUpdateContNumber
          closeForm={handleCloseUpdateContNumber}
          updateData={handleUpdateContNumberData}
        />
      );
    } else if (isUpdateGateIn) {
      return (
        <FormUpdateGateInOut
          closeForm={handleCloseGateIn}
          updateData={handleUpdateGateInData}
          dataUpdate={container}
          isGateIn={isGateIn}
          updateGateOut={handleUpdateGateOutData}
          counter={counter}
        />
      );
    }
  };

  if (
    appError?.statusCode === 500 &&
    appError?.message?.includes("Token đã hết hạn")
  ) {
    LogoutConfirmation(
      navigate,
      dispatch,
      "Đã hết phiên làm việc, vui lòng đăng nhập lại?"
    );
    // Return a placeholder or loading component, for example
    return <div>Loading...</div>;
  }

  const generateTransition = (show: boolean) => (
    <Transition
      show={show}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {displayForm()}
    </Transition>
  );

  const handleImportCSV = (event: React.ChangeEvent<HTMLInputElement>) => {
    const numberOfLengthData = 6;
    if (event.target && event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      if (file.type !== "text/csv") {
        ToastError("Sai định dạng file import!", serverError);
        return;
      }
      Papa.parse(file, {
        complete: function (results) {
          let rawCSV = results.data as string[][];

          if (rawCSV.length > 0) {
            if (rawCSV[0] && rawCSV[0].length === numberOfLengthData) {
              if (
                rawCSV[0][0] !== "Booking No" ||
                rawCSV[0][1] !== "Depot" ||
                rawCSV[0][2] !== "Size" ||
                rawCSV[0][3] !== "Cont No." ||
                rawCSV[0][4] !== "Price/unit" ||
                rawCSV[0][5] !== "Selling Price"
              ) {
                ToastError("Sai định dạng trường dữ liệu!", serverError);
              } else {
                let resultImportSupplier: Array<ContainerApiProps> = [];

                rawCSV.forEach((item, index) => {
                  if (index > 0 && item.length === numberOfLengthData) {
                    let objectSupplier: ContainerApiProps = {
                      bookingNumber: item[0],
                      serviceSupplier: {
                        label: item[0],
                        value: item[0],
                      },
                      size: item[2],
                      containerNumber: item[3],
                      buyingPrice: StringHelper(item[4]),
                      sellingPrice: StringHelper(item[5]),
                    };
                    resultImportSupplier.push(objectSupplier);
                  }
                });
              }
            } else {
              ToastError("Sai định dạng import dữ liệu!", serverError);
            }
          } else {
            ToastError("Không tìm thấy dữ liệu!", serverError);
          }
        },
      });
    }
  };
  return (
    <>
      <div className="bg-blue-100 h-screen ">
        {generateTransition(formStatusState)}
        {generateTransition(formStatusRent)}
        {generateTransition(isUpdateImages)}
        {generateTransition(isUpdateImagesAfterRepair)}
        {generateTransition(isUpdateImagesGateOut)}
        {generateTransition(isUpdateSellCont)}
        {generateTransition(isUpdateMany)}
        {generateTransition(isSellMany)}
        {generateTransition(isUpdateContNumber)}
        {generateTransition(isUpdateGateIn)}
        {slideStatusState && (
          <div
            className="z-10 max-w-[540px] h-[620px] m-auto py-16 px-4 group w-3/4 fixed left-1/2 ml-[-37.5%] min-[2000px]:ml-[-30%] top-16"
            // className="z-10 max-w-[1280px] h-[720px] m-auto py-16 px-4 group w-3/4 fixed left-1/2 ml-[-37.5%] min-[2000px]:ml-[-30%] top-16"
          >
            <Slider
              closeForm={handleCloseSlide}
              isBigger={false}
              currentNameField={currentNameField}
            />
          </div>
        )}
        <div className="flex flex-col bg-slate-50 mx-2 rounded-2xl p-4 ">
          <div className="flex flex-row ml-2">
            <div className="absolute left-5 w-1 bg-blue-400 h-14"></div>
            <div className="flex-none flex-shrink-0">
              <p className="font-sans font-semibold text-3xl">{title}</p>
              <p className="font-sans text-sm italic">{title} Conta</p>
            </div>

            {/* Add button */}
            <div className="flex items-center grow justify-end flex-shrink-0">
              <HiOutlinePlusSm
                onClick={() => handleOpenFormAdd()}
                className="text-4xl bg-blue-600 rounded-full text-white hover:bg-blue-500 cursor-pointer "
              />
            </div>
          </div>
          {/* <Search
            handleSearch={handleSearch}
            openFormUpdateMany={handleOpenFormUpdateMany}
            openFormSellMany={handleOpenFormSellMany}
            openFormUpdateNumberCont={handleOpenFormUpdateNumberCont}
            dataServiceSupplier={serviceSupplierData}
            dataSupplier={supplierData}
          /> */}

          {/* 
          
          <div className="overflow-hidden">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200 table-auto mb-4">
                      <thead className="bg-gray-50">
                        <TableHeader titles={headersContainerAdmin} />
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td colSpan={4} className="text-center pl-4 pt-4">
                              <LoadingComponent />
                            </td>
                          </tr>
                        ) : (data && data?.length <= 0) || data == null ? (
                          <tr>
                            <td colSpan={4} className="text-center pl-4 pt-4">
                              <NotFoundLoading />
                            </td>
                          </tr>
                        ) : (
                          <ListItem
                            containerUpdate={container}
                            loadingImagesGateIn={loadingImagesGateIn}
                            loadingImagesMNR={loadingImagesNMR}
                            loadingImagesGateOut={loadingImagesGateOut}
                            data={data}
                            openFormUpdate={(id) => handleOpenFormUpdate(id)}
                            openFormUpdateImages={(id) =>
                              handleOpenFormUpdateImages(id)
                            }
                            openFormUpdateImagesAfterRepair={(id) =>
                              handleOpenFormUpdateImagesAfterRepair(id)
                            }
                            openFormUpdateImagesGateOut={(id) =>
                              handleOpenFormUpdateImagesGateOut(id)
                            }
                            socket={socket}
                            userAuth={userAuth}
                            openSlide={(imageId, currentNameField) =>
                              handleOpenSlide(imageId, currentNameField)
                            }
                            openFormSellCont={(id) =>
                              handleOpenFormSellCont(id)
                            }
                            openFormGateIn={(id) => handleOpenFormGateIn(id)}
                          />
                        )}
                      </tbody>
                    </table>
                    {totalPage > 1 ? (
                      <ReactPaginate
                        key={searchKey}
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={totalPage} // Replace with the total number of pages
                        previousLabel="<"
                        pageClassName="inline-block px-4 py-1 rounded-lg mx-1 bg-gray-200 text-gray-800"
                        pageLinkClassName="block w-full h-full"
                        previousClassName="inline-block px-2 py-1 rounded-lg mx-1 bg-gray-200 text-gray-800"
                        previousLinkClassName="block w-full h-full"
                        nextClassName="inline-block px-2 py-1 rounded-lg mx-1 bg-gray-200 text-gray-800"
                        nextLinkClassName="block w-full h-full"
                        breakLabel="..."
                        breakClassName="inline-block px-2 py-1 mx-1 text-gray-600"
                        breakLinkClassName=""
                        containerClassName="flex justify-start my-4 "
                        activeClassName="background-color-base-paging text-white"
                        disabledClassName="opacity-50 cursor-not-allowed"
                        renderOnZeroPageCount={null}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          */}

          {loading && data && data?.length <= 0 ? (
            <div className="text-center pl-4 pt-4">
              <LoadingComponent />
            </div>
          ) : (
            <TableContainerAdmin
              dataContainer={data}
              openFormUpdate={(id: string) => handleOpenFormUpdate(id)}
              openFormRent={(id: string) => handleOpenFormRent(id)}
              containerUpdate={container}
              loadingImagesGateIn={loadingImagesGateIn}
              loadingImagesMNR={loadingImagesNMR}
              loadingImagesGateOut={loadingImagesGateOut}
              openFormUpdateImages={(id) => handleOpenFormUpdateImages(id)}
              openFormUpdateImagesAfterRepair={(id) =>
                handleOpenFormUpdateImagesAfterRepair(id)
              }
              openFormUpdateImagesGateOut={(id) =>
                handleOpenFormUpdateImagesGateOut(id)
              }
              socket={socket}
              userAuth={userAuth}
              openSlide={(imageId, currentNameField) =>
                handleOpenSlide(imageId, currentNameField)
              }
              openFormSellCont={(id) => handleOpenFormSellCont(id)}
              openFormGateIn={(id) => handleOpenFormGateIn(id)}
              openFormUpdateMany={handleOpenFormUpdateMany}
              openFormSellMany={handleOpenFormSellMany}
              openFormUpdateNumberCont={handleOpenFormUpdateNumberCont}
            />
          )}
        </div>
      </div>
    </>
  );
};
