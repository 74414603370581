import React, { useEffect, useState } from "react";
import { Board, DragDropProvider } from "./components";
import { api } from "./assets";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { useSelector } from "react-redux";
import {
  addAction,
  getAllWithoutLimitAction,
  selectLocationContainers,
  updateNameRowLocationAction,
} from "../../../redux/slices/LocationContainer/LocationContainerSlice";
import { v4 as uuidv4 } from "uuid";
import { HiOutlinePlusSm } from "react-icons/hi";
import { closeForm, openForm } from "../../../redux/slices/formSlices";
import { FormAdd } from "./components/Form/FormAdd";
import {
  AddLocationApiProps,
  LocationApiUpdateProps,
  UpdateLocationContainerApiProps,
  locationContainerApiProps,
} from "../../../types/apiType";
import { Transition } from "@headlessui/react";
import ToastSuccess from "../../../components/Toast/success";
import ToastError from "../../../components/Toast/error";
import { selectUser } from "../../../redux/slices/User/UserSlice";
const ContainerInContaLocationE = () => {
  const title = "Quản lý Vị trí container khu E";
  const dispatch = useAppDispatch();
  const locationContainerData = useSelector(selectLocationContainers);
  const { data, serverError } = locationContainerData;
  const userData = useSelector(selectUser);
  const { userAuth } = userData;

  const [formStatusState, setFormStatusState] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [locationContainer] = useState<LocationApiUpdateProps>({
    idColumn: "",
    title: "",
    idContainer: "",
    rowContainerNameArray: "",
  });
  const [dataUpdate, setDataUpdate] = useState<UpdateLocationContainerApiProps>(
    {
      _id: "",
      pickUpDirection: "",
      rowContainerName: "",
    }
  );

  const [isRoleApprove, setIsRoleApprove] = useState(false);

  useEffect(() => {
    dispatch(getAllWithoutLimitAction("EFG"));
    if (userAuth?.role === 0 || userAuth?.role === 3) {
      setIsRoleApprove(true);
    } else {
      setIsRoleApprove(false);
    }
  }, []);

  const handleOpenFormAdd = () => {
    setFormStatusState(true);
    setIsUpdate(false);
    const action = openForm();
    dispatch(action);
  };

  const handleCloseForm = () => {
    setFormStatusState(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleAddData = async (data: AddLocationApiProps) => {
    setFormStatusState(false);

    const action = await dispatch(addAction(data));

    if (addAction.fulfilled.match(action)) {
      ToastSuccess(action.payload.message);
      window.location.reload();
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleOpenFormUpdateRowLocation = (
    data: UpdateLocationContainerApiProps
  ) => {
    setFormStatusState(true);
    setIsUpdate(true);
    const action = openForm();
    dispatch(action);
    setDataUpdate(data);
  };

  const handleUpdateData = async (data: UpdateLocationContainerApiProps) => {
    setFormStatusState(false);
    setIsUpdate(false);

    const action = await dispatch(updateNameRowLocationAction(data));

    if (updateNameRowLocationAction.fulfilled.match(action)) {
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const displayForm = () => {
    if (formStatusState) {
      return (
        <FormAdd
          closeForm={handleCloseForm}
          addData={handleAddData}
          isUpdate={isUpdate}
          dataUpdate={dataUpdate}
          updateData={handleUpdateData}
        />
      );
    }
  };

  return (
    <div className="bg-blue-100 h-screen ">
      <Transition
        show={formStatusState}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {displayForm()}
      </Transition>
      <div className="flex flex-col bg-slate-50 mx-2 rounded-2xl p-4">
        <div className="flex flex-row ml-2 justify-between overflow-hidden overflow-x-auto">
          <div className="absolute left-5 w-1 bg-blue-400 h-14"></div>
          <div className="flex-none flex-shrink-0">
            <p className="font-sans font-semibold text-3xl">{title}</p>
            <p className="font-sans text-sm italic">{title}</p>
          </div>
          {isRoleApprove && (
            <div className="flex items-center grow justify-end flex-shrink-0">
              <HiOutlinePlusSm
                onClick={() => handleOpenFormAdd()}
                className="text-4xl bg-blue-600 rounded-full text-white hover:bg-blue-500 cursor-pointer "
              />
            </div>
          )}
        </div>

        <div className="overflow-hidden">
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  {data?.map((item) => (
                    <div key={uuidv4()}>
                      <div>
                        <DragDropProvider
                          data={item.columnContainer}
                          rowContainerName={item.rowContainerName}
                          pickUpDirection={item.pickUpDirection}
                          id={item._id}
                        >
                          <Board
                            openFormUpdateRowLocation={(
                              data: UpdateLocationContainerApiProps
                            ) => handleOpenFormUpdateRowLocation(data)}
                          />
                        </DragDropProvider>
                      </div>
                      <div className="w-full bg-gray-100 h-[24px]"></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContainerInContaLocationE;
