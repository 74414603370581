// import { electricityTariffTier } from "./electricityTariff";
// import electricityTariff from "./electricityTariff";
import { logoContaBase64 } from "./logoContaBase64";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {
  dateDDMMYYStringHelper,
  dateTimeGateInOutHelper,
  plusAnyDateHelper,
} from "../DateHelper";
import { checkedBox, noCheckedBox } from "./checkboxSVGHelper";
import { imageCont1, imageCont2, imageCont3 } from "./imageContainerBase64";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const gateOutEIRPDF = (data, counter) => {
  const documentDefinition = {
    pageSize: "A5",
    pageOrientation: "landscape",
    content: [
      {
        columns: [
          {
            // Image on the left
            image: `${logoContaBase64}`,
            width: 120,
            height: 70,
          },

          {
            // Texts on the right
            stack: [
              {
                text: "CONTA VIET NAM COMPANY LIMITED",
                style: "text",
                // bold: true,
                margin: [100, 10, 0, 3],
              },
              {
                text: "17/4B Điện Biên Phủ, P. 22, Quận Bình Thạnh, TPHCM",
                style: "text",
                margin: [60, 0, 0, 3],
              },
              {
                text: "Email: info@conta.vn - Phone: +84 94 355 9993",
                style: "text",
                margin: [70, 0, 0, 3],
              },
              //   {
              //     text: "Phone: +84 94 355 9993",
              //     style: "text",
              //     margin: [90, 0, 0, 0],
              //   },
            ],
            alignment: "left",
          },
        ],
        margin: [60, 0, 0, 0], // Optional: adjust the spacing below the columns
      },
      {
        text: "PHIẾU GIAO NHẬN CONTAINER",
        style: "header",
        bold: true,
        alignment: "center", // Center-align the header text
      },
      {
        text: "EQUIPMENT INTERCHANGE RECEIPT (EIR)",
        style: "text",
        bold: true,
        alignment: "center", // Center-align the header text
      },
      {
        text: `Số phiếu (EIR no):      ${dateDDMMYYStringHelper(
          data.listLifecycle.at(-1)?.dateGateOut
        )}${counter.autoIncrementId}`,
        style: "text",
        bold: true,
        alignment: "right", // Center-align the header text
        margin: [0, 0, 30, 0],
      },
      {
        text: `Ngày (Date):     ${dateDDMMYYStringHelper(
          data.listLifecycle.at(-1)?.dateGateOut
        )}`,
        style: "text",
        alignment: "right", // Center-align the header text
        margin: [0, 0, 45, 0],
      },
      { text: "\n" },
      {
        columns: [
          {
            text: `Giao cho/Nhận của: ${data.buyer.label}`,
            style: "textSmall",
            bold: true,
            alignment: "left", // Center-align the header text
            margin: [0, 0, 30, 0],
          },

          {
            text: `Số Booking: ${data.bookingNumber}`,
            style: "textSmall",
            bold: true,
            margin: [0, 0, 45, 0],
          },
        ],
        margin: [20, 0, 0, 0], // Optional: adjust the spacing below the columns
      },
      {
        columns: [
          {
            text: `(Delivery to/Receiver from)`,
            style: "subTextSmall",
            alignment: "left", // Center-align the header text
            margin: [0, 0, 30, 0],
          },

          {
            text: `(Booking No.)`,
            style: "subTextSmall",
            margin: [0, 0, 95, 0],
          },
        ],
        margin: [20, 0, 0, 0], // Optional: adjust the spacing below the columns
      },
      {
        columns: [
          {
            text: `Số Lệnh cấp: ${
              data.bookingManySaleNumber
                ? data.bookingManySaleNumber
                : data.saleBookingNumber
            }`,
            style: "textSmall",
            bold: true,
            alignment: "left", // Center-align the header text
            margin: [0, 0, 30, 0],
          },

          {
            text: `Chủ khai thác: ${data.lineOperation.label}`,
            style: "textSmall",
            bold: true,
            margin: [0, 0, 45, 0],
          },
        ],
        margin: [20, 5, 0, 0], // Optional: adjust the spacing below the columns
      },
      {
        columns: [
          {
            text: `(Release No.)`,
            style: "subTextSmall",
            alignment: "left", // Center-align the header text
            margin: [20, 0, 0, 0],
          },

          {
            text: `(Line OPR)`,
            style: "subTextSmall",
            margin: [10, 0, 0, 0],
          },
        ],
      },
      { text: "\n" },
      {
        table: {
          headerRows: 1,
          // widths: [120, 160, 100, 100, 120, 160, 160, 100],
          widths: [60, 80, 60, 60, 60, 60, 60, 40],
          body: [
            [
              {
                // Texts on the right
                stack: [
                  {
                    text: "Container số:",
                    style: "subHead",
                    bold: true,
                  },
                  {
                    text: "(CONTR No.)",
                    style: "subTextSmall",
                  },
                ],
                margin: [0, 5, 0, 0],
                fillColor: "#E1E1E1",
              },
              {
                text: data.containerNumber,
                style: "text",
                bold: true,
                margin: [0, 5, 0, 0],
              },
              {
                // Texts on the right
                stack: [
                  {
                    text: "Kích cỡ:",
                    style: "subHead",
                    bold: true,
                  },
                  {
                    text: "(Size/Type)",
                    style: "subTextSmall",
                  },
                ],
                margin: [0, 5, 0, 0],
                fillColor: "#E1E1E1",
              },
              {
                text: data.size,
                style: "text",
                bold: true,
                margin: [0, 5, 0, 0],
              },
              {
                stack: [
                  {
                    text: "Trọng lượng:",
                    style: "subHead",
                    bold: true,
                  },
                  {
                    text: "(Weight)",
                    style: "subTextSmall",
                  },
                ],
                margin: [0, 5, 0, 0],
                fillColor: "#E1E1E1",
              },
              {
                stack: [
                  {
                    columns: [
                      {
                        svg: `<svg viewBox="0 0 24 24" width="12" height="12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --> <title>ic_fluent_checkbox_checked_24_regular</title> <desc>Created with Sketch.</desc> <g id="🔍-Product-Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="ic_fluent_checkbox_checked_24_regular" fill="#212121" fill-rule="nonzero"> <path d="M18.25,3 C19.7687831,3 21,4.23121694 21,5.75 L21,18.25 C21,19.7687831 19.7687831,21 18.25,21 L5.75,21 C4.23121694,21 3,19.7687831 3,18.25 L3,5.75 C3,4.23121694 4.23121694,3 5.75,3 L18.25,3 Z M18.25,4.5 L5.75,4.5 C5.05964406,4.5 4.5,5.05964406 4.5,5.75 L4.5,18.25 C4.5,18.9403559 5.05964406,19.5 5.75,19.5 L18.25,19.5 C18.9403559,19.5 19.5,18.9403559 19.5,18.25 L19.5,5.75 C19.5,5.05964406 18.9403559,4.5 18.25,4.5 Z M10,14.4393398 L16.4696699,7.96966991 C16.7625631,7.6767767 17.2374369,7.6767767 17.5303301,7.96966991 C17.7965966,8.23593648 17.8208027,8.65260016 17.6029482,8.94621165 L17.5303301,9.03033009 L10.5303301,16.0303301 C10.2640635,16.2965966 9.84739984,16.3208027 9.55378835,16.1029482 L9.46966991,16.0303301 L6.46966991,13.0303301 C6.1767767,12.7374369 6.1767767,12.2625631 6.46966991,11.9696699 C6.73593648,11.7034034 7.15260016,11.6791973 7.44621165,11.8970518 L7.53033009,11.9696699 L10,14.4393398 L16.4696699,7.96966991 L10,14.4393398 Z" id="🎨Color"> </path> </g> </g> </g></svg>`,
                        style: "subHead",
                        bold: true,
                      },

                      {
                        text: `32.500 KGS`,
                        style: "subHead",
                        bold: true,
                        margin: [-18, 0, 0, 0],
                      },
                    ],
                  },

                  {
                    columns: [
                      {
                        svg: `<svg viewBox="0 0 512 512" width="12" height="12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>checkbox-component-unchecked</title> <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="drop" fill="#000000" transform="translate(64.000000, 64.000000)"> <path d="M384,1.42108547e-14 L384,384 L1.42108547e-14,384 L1.42108547e-14,1.42108547e-14 L384,1.42108547e-14 Z M362.666667,21.3333333 L21.3333333,21.3333333 L21.3333333,362.666667 L362.666667,362.666667 L362.666667,21.3333333 Z" id="Combined-Shape"> </path> </g> </g> </g></svg>`,
                        style: "subHead",
                        bold: true,
                      },

                      {
                        text: `30.480 KGS`,
                        style: "subHead",
                        bold: true,
                        margin: [-18, 0, 0, 0],
                      },
                    ],
                  },
                ],
                margin: [0, 5, 0, 0],
              },
              {
                stack: [
                  {
                    text: "Năm sản xuất:",
                    style: "subHead",
                    bold: true,
                  },
                  {
                    text: "(YOM)",
                    style: "subTextSmall",
                  },
                ],
                margin: [0, 5, 0, 0],
                fillColor: "#E1E1E1",
              },
              {
                text: data.yearOfManufacture,
                style: "text",
                bold: true,
                margin: [0, 5, 0, 0],
              },
            ],
            [
              {
                // Texts on the right
                stack: [
                  {
                    text: "Số xe:",
                    style: "subHead",
                    bold: true,
                  },
                  {
                    text: "(Truck No.)",
                    style: "subTextSmall",
                  },
                ],
                margin: [0, 5, 0, 0],
                fillColor: "#E1E1E1",
              },
              {
                text: data.listLifecycle.at(-1)?.trunkNumberGateIn,
                style: "text",
                bold: true,
                margin: [0, 5, 0, 0],
              },
              {
                // Texts on the right
                stack: [
                  {
                    text: "Nhập xuất:",
                    style: "subHead",
                    bold: true,
                  },
                  {
                    text: "(In/out)",
                    style: "subTextSmall",
                  },
                ],
                margin: [0, 5, 0, 0],
                fillColor: "#E1E1E1",
              },

              {
                stack: [
                  {
                    columns: [
                      {
                        svg: noCheckedBox,
                        style: "subHead",
                        bold: true,
                      },

                      {
                        text: `Nhập (in)`,
                        style: "subHead",
                        bold: true,
                        margin: [-18, 0, 0, 0],
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        svg: checkedBox,
                        style: "subHead",
                        bold: true,
                      },

                      {
                        text: `Xuất (out)`,
                        style: "subHead",
                        bold: true,
                        margin: [-18, 0, 0, 0],
                      },
                    ],
                  },
                ],
                margin: [0, 5, 0, 0],
              },
              {
                stack: [
                  {
                    text: "Trạng thái:",
                    style: "subHead",
                    bold: true,
                  },
                  {
                    text: "(Status)",
                    style: "subTextSmall",
                  },
                ],
                margin: [0, 5, 0, 0],
                fillColor: "#E1E1E1",
              },
              {
                stack: [
                  {
                    columns: [
                      {
                        svg:
                          data.listLifecycle.at(-1)?.noteGateIn == "D"
                            ? checkedBox
                            : noCheckedBox,
                        style: "subHead",
                        bold: true,
                      },

                      {
                        text: `Hư hỏng (Damage)`,
                        style: "subHead",
                        bold: true,
                        margin: [-18, 0, 0, 0],
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        svg:
                          data.listLifecycle.at(-1)?.noteGateIn != "D"
                            ? checkedBox
                            : noCheckedBox,
                        style: "subHead",
                        bold: true,
                      },

                      {
                        text: `AV`,
                        style: "subHead",
                        bold: true,
                        margin: [-18, 0, 0, 0],
                      },
                    ],
                  },
                ],
                margin: [0, 5, 0, 0],
                colSpan: 3,
              },
            ],
          ],
        },
        margin: [20, 0, 20, 0],
      },
      {
        table: {
          headerRows: 1,
          widths: ["auto"],
          body: [
            [
              {
                columns: [
                  {
                    // Image on the left
                    image: `${imageCont1}`,
                    width: 160,
                    height: 70,
                  },

                  {
                    // Image on the left
                    image: `${imageCont2}`,
                    width: 200,
                    height: 70,
                  },
                  {
                    // Image on the left
                    image: `${imageCont3}`,
                    width: 183,
                    height: 70,
                  },
                ],
              },
            ],
          ],
        },
        margin: [20, 5, 20, 0],
      },
      {
        stack: [
          {
            // text: `GHI CHÚ: ${data.listLifecycle.at(-1)?.noteGateOut}`,
            text: `GHI CHÚ: ${data.listLifecycle.at(-1)?.noteGateOut}`,
            style: "textSmall",
            bold: true,
            alignment: "left", // Center-align the header text
            margin: [0, 0, 0, 0],
          },
          {
            text: `(REMARK)`,
            style: "subTextSmall",
            alignment: "left", // Center-align the header text
            margin: [0, 0, 0, 0],
          },
        ],
        margin: [20, 5, 0, 0], // Optional: adjust the spacing below the columns
      },
      {
        columns: [
          {
            text: `ĐẠI DIỆN BÊN GIAO/NHẬN`,
            style: "textSmall",
            bold: true,
            alignment: "left", // Center-align the header text
            margin: [0, 0, 30, 0],
          },

          {
            text: `ĐẠI DIỆN BÃI CONTAINER`,
            style: "textSmall",
            bold: true,
            margin: [0, 0, 45, 0],
          },
        ],
        margin: [80, 0, 0, 0], // Optional: adjust the spacing below the columns
      },
      {
        columns: [
          {
            text: `(For delivering/receiving carrier)`,
            style: "subTextSmall",
            alignment: "left", // Center-align the header text
            margin: [0, 0, 30, 0],
          },

          {
            text: `(CYS)`,
            style: "subTextSmall",
            margin: [35, 0, 0, 0],
          },
        ],
        margin: [80, 0, 0, 0], // Optional: adjust the spacing below the columns
      },
    ],
    styles: {
      header: {
        fontSize: 12,
        bold: true,
        margin: [0, 0, 0, 0], // Optional: adjust the spacing around the header
      },
      text: {
        fontSize: 11,
        margin: [0, 0, 0, 0],
        // margin: [2, 6], // Optional: adjust the spacing between text lines
      },
      subHead: {
        fontSize: 9,
        // margin: [2, 6], // Optional: adjust the spacing between text lines
      },
      textSmall: {
        fontSize: 8,
        // margin: [2, 6], // Optional: adjust the spacing between text lines
      },
      subTextSmall: {
        fontSize: 7,
        // margin: [2, 6], // Optional: adjust the spacing between text lines
      },
      subText: {
        fontSize: 11,
      },
      textCenter: {
        fontSize: 11,
        margin: [2, 18],
      },
      bigText: {
        fontSize: 14,
        margin: [2, 6], // Optional: adjust the spacing between text lines
      },
    },
    pageMargins: [0, 0, 0, 0],
  };
  // Create the PDF document
  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
  // Download the PDF document
  pdfDocGenerator.download(`${data.containerNumber}.pdf`);
};
