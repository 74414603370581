import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AiOutlineClose } from "react-icons/ai";
import { UpdateContNumberProps } from "../../../types/apiType";
import { useSelector } from "react-redux";
import { isoCheckDigit } from "../../../utils/containerCheckLigit";
import { selectSelects } from "../../../redux/slices/SelectCheckbox/SelectCheckboxSlice";

interface FormProps {
  closeForm: () => void;
  updateData: (data: UpdateContNumberProps) => Promise<void>;
}

export const FormUpdateContNumber = (props: FormProps) => {
  //declare value in fields
  const [contNumber, setContNumber] = useState("");

  // get props to index components
  const { closeForm, updateData } = props;
  const dataSelected = useSelector(selectSelects);
  const { selectedAllContainerRent } = dataSelected;
  const formSchema = Yup.object().shape({
    contNumber: Yup.string()
      .required("*Dữ liệu bắt buộc!")
      .test(
        "containerNumber",
        "*Xuất hiện số container không hợp lệ!",
        (value) => {
          if (!value) {
            // Allow empty values
            return false;
          }
          let valueFormatted = value
            .split(/[,\n]/) // Split by both commas and newlines
            .map((item) => item.trim()) // Remove leading/trailing spaces
            .filter((item) => item !== "")
            .every(
              (item) => item.at(-1) === isoCheckDigit(item.trim()).toString()
            );

          return valueFormatted;
        }
      )
      .test(
        "containerNumber",
        "*Không trùng khớp số lượng cập nhật!",
        (value) => {
          if (!value) {
            // Allow empty values
            return false;
          }
          let valueFormatted = value
            .split(/[,\n]/) // Split by both commas and newlines
            .map((item) => item.trim()) // Remove leading/trailing spaces
            .filter((item) => item !== "").length;
          return valueFormatted === selectedAllContainerRent.length ? true : false;
        }
      ),
  });

  //useRef
  const inputRef = useRef<HTMLInputElement | null>(null);
  //get dataUpdate

  // close form event
  const handleCloseForm = () => {
    closeForm();
  };

  const handleUpdateData = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    const formattedValues = formik.values.contNumber
      .split(/[,\n]/) // Split by both commas and newlines
      .map((item) => item.trim()) // Remove leading/trailing spaces
      .filter(
        (item) =>
          item !== "" &&
          item.at(-1) === isoCheckDigit(item.trim()).toString()
      ); // Filter out empty elements
    const updateContNumberData: UpdateContNumberProps = {
      listContId: selectedAllContainerRent,
      listContNumber: formattedValues,
    };
    updateData(updateContNumberData);
  };

  // check show button action
  const showButtonAction = () => {
    return (
      <button
        type="submit"
        onClick={(e) => handleUpdateData(e)}
        className="focus:outline-none text-white bg-green-600 hover:bg-green-500 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 disabled:cursor-not-allowed"
        disabled={!formik.isValid}
      >
        Cập nhật tất cả
      </button>
    );
  };
  //update
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      contNumber,
    },
    validationSchema: formSchema,
    onSubmit: () => {},
  });

  const focus = () => {
    inputRef.current?.focus();
  };

  useEffect(() => {
    focus();
  }, []);

  return (
    <>
      <div className="bg-black opacity-50 fixed w-full h-full top-0 z-40"></div>
      <div className="w-full sm:w-1/2 max-h-full mb-2 p-4 bg-white fixed overflow-y-scroll top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 animated-image-slide z-50 border-2 border-state-500">
        <p className="font-sans text-2xl md:text-3xl">Cập nhật số container</p>
        <button
          className="w-full inline-flex justify-end"
          onClick={() => handleCloseForm()}
        >
          <AiOutlineClose className="text-3xl" />
        </button>

        <form>
          <div className="flex lg:flex-row flex-col justify-between mb-8">
            <div className="flex flex-col w-full lg:ml-1 ml-0 mt-6 lg:mt-0">
              <div className="relative z-0 group border border-gray-300 rounded-md">
                <textarea
                  name="floating_contNumber"
                  id="floating_contNumber"
                  className="block ml-2 py-2.5 px-0 w-full h-52 text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.contNumber}
                  onChange={formik.handleChange("contNumber")}
                  onBlur={formik.handleBlur("contNumber")}
                  autoFocus
                />
                <label
                  htmlFor="floating_contNumber"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  Danh sách số container <span className="text-red-500">*</span>
                </label>
              </div>
              <div className="text-red-400 mb-2">
                {formik.touched.contNumber && formik.errors.contNumber}
              </div>
            </div>
          </div>
          {showButtonAction()}
          <button
            type="button"
            className="focus:outline-none text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
            onClick={() => handleCloseForm()}
          >
            Hủy
          </button>
        </form>
      </div>
    </>
  );
};
