import React from "react";
import {
  BookingSaleApiProps,
  ContainerApiProps,
  UserApiProps,
} from "../../types/apiType";
import { v4 as uuidv4 } from "uuid";
import {
  dateStringHelper,
  dateTimeGateInOutHelper,
} from "../../utils/DateHelper";
import { containerStatusHelper } from "../../utils/StatusHelper";
import StepperComponent from "../../components/StepperComponent";
import { Tab, Tabs } from "../../components/TabComponent";
import StepperNoData from "../../components/StepperComponent/StepperNoData";
import LoadingComponent from "../../components/LoadingComponent";
import NotFoundLoading from "../../components/LoadingComponent/NotFoundLoading";
interface ListItemProps {
  data: ContainerApiProps;
  containerRevenue: string | undefined;
  userAuth: UserApiProps | undefined;
  openSlide: (id: string, currentNameField: string) => void;
  bookingSalesData: BookingSaleApiProps;
  loading: boolean;
}

export const ListItem: React.FC<ListItemProps> = ({
  data,
  containerRevenue,
  userAuth,
  openSlide,
  bookingSalesData,
  loading,
}) => {
  const openSlideShow = (id: string, currentNameField: string) => {
    openSlide(id, currentNameField);
  };

  const shouldRenderTabContainer =
    data && Object.keys(data).length > 0 && data.constructor === Object;
  const shouldRenderTabBookingSale =
    bookingSalesData &&
    Object.keys(bookingSalesData).length > 0 &&
    bookingSalesData.constructor === Object;

  return (
    <>
      {loading ? (
        <div className="text-center pl-4 pt-4">
          <LoadingComponent />
        </div>
      ) : (data && data?.length <= 0) || data == null ? (
        <div className="text-center pl-4 pt-4">
          <NotFoundLoading />
        </div>
      ) : (
        <div className="ml-4">
          <div>
            <Tabs>
              {shouldRenderTabContainer ? (
                <Tab label="Danh mục thông tin container">
                  <div className="py-4">
                    <h2 className="text-lg font-medium mb-2">
                      Thông tin container
                    </h2>
                    <div className="overflow-hidden overflow-x-auto">
                      <div className="mt-6">
                        <ol className="items-center flex flex-wrap w-full gap-5 sm:flex sm:gap-5 rtl:space-x-reverse">
                          <StepperComponent
                            numberStep={1}
                            nameStep="Số hóa đơn"
                            data={data?.invoiceNumber}
                          />
                          <StepperComponent
                            numberStep={2}
                            nameStep="Số booking"
                            data={data?.bookingNumber}
                          />{" "}
                          <StepperComponent
                            numberStep={3}
                            nameStep="Nhà cung cấp"
                            data={data?.supplier?.label}
                          />
                          <StepperComponent
                            numberStep={4}
                            nameStep="Hãng khai thác"
                            data={data?.lineOperation?.label}
                          />
                          <StepperComponent
                            numberStep={5}
                            nameStep="Vị trí"
                            data={data?.serviceSupplier?.label}
                          />
                          <StepperComponent
                            numberStep={6}
                            nameStep="Số container"
                            data={data?.containerNumber}
                          />
                        </ol>
                        <ol className="items-center flex flex-wrap w-full gap-5 sm:flex sm:gap-5 rtl:space-x-reverse mt-5 sm:mt-12">
                          {data?.containerCondition ? (
                            <StepperComponent
                              numberStep={7}
                              nameStep="Tình trạng container"
                              data={data?.containerCondition}
                            />
                          ) : null}
                          <StepperComponent
                            numberStep={8}
                            nameStep="Kích thước container"
                            data={data?.size}
                          />
                          <StepperComponent
                            numberStep={9}
                            nameStep="Người bán"
                            data={data?.seller?.label}
                          />
                          <StepperComponent
                            numberStep={10}
                            nameStep="Người mua"
                            data={data?.buyer?.label}
                          />

                          <StepperComponent
                            numberStep={11}
                            nameStep="Ngày bán"
                            data={dateStringHelper(data?.sellContDate)}
                          />
                          {/* {data.contStatus && (
                          <StepperComponent
                            numberStep={12}
                            nameStep="Trạng thái container"
                            data={containerStatusHelper(data.contStatus)}
                          />
                        )} */}

                          <StepperComponent
                            numberStep={12}
                            nameStep="Trạng thái container"
                            data={containerStatusHelper(data?.contStatus)}
                            hightLight
                          />

                          {/* {data.isReceipt ? (
                          <StepperComponent
                            numberStep={13}
                            nameStep="Tình trạng phiếu thu"
                            data={
                              data.isReceipt
                                ? "Đã lập phiếu thu"
                                : "Chưa lập phiếu thu"
                            }
                          />
                        ) : null} */}
                        </ol>
                      </div>
                      <div className="flex gap-5 sm:flex sm:gap-5 mt-5 sm:mt-12">
                        <StepperComponent
                          numberStep={13}
                          nameStep="Tình trạng phiếu thu"
                          data={
                            data?.isReceipt
                              ? "Đã lập phiếu thu"
                              : "Chưa lập phiếu thu"
                          }
                        />
                        <StepperComponent
                          numberStep={14}
                          nameStep="Ghi chú phiếu thu"
                          data={data?.notes}
                        />
                        <StepperComponent
                          numberStep={15}
                          nameStep="Ghi chú sửa chữa"
                          data={data?.MNR?.noteMNR}
                        />
                        <StepperComponent
                          numberStep={16}
                          nameStep="Loại máy"
                          data={data?.brandOfUnit?.label}
                        />
                        <>
                          {!data?.hasImage && !data?.images?.length ? (
                            <StepperNoData
                              numberStep={17}
                              nameStep="Chưa có hình ảnh gate in"
                            />
                          ) : (
                            <div
                              className="flex gap-4 divide-x text-blue-600 group cursor-pointer "
                              onClick={() =>
                                openSlideShow(data?._id!, "images")
                              }
                            >
                              <StepperNoData
                                numberStep={17}
                                nameStep="Xem hình gate in"
                              />
                            </div>
                          )}
                        </>
                        <>
                          {!data?.hasImagesAfterRepair &&
                          !data?.imagesAfterRepair?.length ? (
                            <div>
                              <StepperNoData
                                numberStep={18}
                                nameStep="Chưa có hình sửa chữa"
                              />
                            </div>
                          ) : (
                            <div
                              className="flex gap-4 divide-x text-blue-600 group cursor-pointer "
                              onClick={() =>
                                openSlideShow(data?._id!, "imagesAfterRepair")
                              }
                            >
                              <StepperNoData
                                numberStep={18}
                                nameStep="Xem hình sửa chữa"
                              />
                            </div>
                          )}
                        </>
                        {/* <>
                        {!data?.hasImagesGateOut &&
                        !data?.imagesGateOut?.length ? (
                          <StepperNoData
                            numberStep={19}
                            nameStep="Chưa có hình gate out"
                          />
                        ) : (
                          <div
                            className="flex gap-4 divide-x text-blue-600 group cursor-pointer"
                            onClick={() => openSlideShow("imagesGateOut")}
                          >
                            <StepperNoData
                              numberStep={19}
                              nameStep="Xem hình gate out"
                            />
                          </div>
                        )}
                      </>
                      <StepperComponent
                        numberStep={20}
                        nameStep="Số ngày lưu bãi"
                        data={data.storageNumber}
                      /> */}
                      </div>
                      <div className="flex gap-5 sm:flex sm:gap-5 mt-6 sm:mt-12">
                        <>
                          {!data?.hasImagesGateOut &&
                          !data?.imagesGateOut?.length ? (
                            <StepperNoData
                              numberStep={19}
                              nameStep="Chưa có hình gate out"
                            />
                          ) : (
                            <div
                              className="flex gap-4 divide-x text-blue-600 group cursor-pointer"
                              onClick={() =>
                                openSlideShow(data?._id!, "imagesGateOut")
                              }
                            >
                              <StepperNoData
                                numberStep={19}
                                nameStep="Xem hình gate out"
                              />
                            </div>
                          )}
                        </>
                        <StepperComponent
                          numberStep={20}
                          nameStep="Số ngày lưu bãi"
                          data={data?.storageNumber}
                        />
                        <StepperComponent
                          numberStep={21}
                          nameStep="Giá bán"
                          data={new Intl.NumberFormat("de-DE").format(
                            parseInt(
                              !data?.sellingPrice ? "0" : data?.sellingPrice
                            )
                          )}
                        />
                        <StepperComponent
                          numberStep={22}
                          nameStep="VAT(%)"
                          data={data?.VAT}
                        />
                        {/* <StepperComponent
                        numberStep={22}
                        nameStep="Số booking bán"
                        data={
                          !data?.bookingManySaleNumber
                            ? ""
                            : data?.bookingManySaleNumber
                        }
                      />
                      <StepperComponent
                        numberStep={23}
                        nameStep="Số container còn lại trong booking"
                        data={
                          !data?.bookingManySale?.label
                            ? ""
                            : data?.bookingManySale?.label
                        }
                        hightLight={true}
                      /> */}
                      </div>
                      <div className="mt-8">
                        {data?.listLifecycle?.map((itemLifeCycle, index) => (
                          <ol
                            className="relative border-s border-gray-200 dark:border-gray-700 ml-4"
                            key={uuidv4()}
                          >
                            <li className="mb-10 ms-6">
                              <span className="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                                <svg
                                  className="w-2.5 h-2.5 text-blue-800 dark:text-blue-300"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                                </svg>
                              </span>
                              <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white">
                                Vòng đời {index + 1}{" "}
                                {data.listLifecycle?.length === index + 1 ? (
                                  <span className="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 ms-3">
                                    Latest
                                  </span>
                                ) : null}
                              </h3>
                              <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                                {itemLifeCycle.dateGateIn === undefined
                                  ? "Gate In: Không xác định"
                                  : "Gate In: " +
                                    dateTimeGateInOutHelper(
                                      itemLifeCycle.dateGateIn
                                    )}{" "}
                                -{" "}
                                {itemLifeCycle.dateGateOut === undefined
                                  ? "Gate Out: Không xác định"
                                  : "Gate Out: " +
                                    dateTimeGateInOutHelper(
                                      itemLifeCycle.dateGateOut
                                    )}
                              </time>

                              <ol className="items-center sm:flex mt-4">
                                <li className="relative mb-6 sm:mb-0">
                                  <div className="flex items-center">
                                    <div className="z-10 flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-0 ring-white dark:bg-blue-900 sm:ring-8 dark:ring-gray-900 shrink-0">
                                      <svg
                                        className="w-2.5 h-2.5 text-blue-800 dark:text-blue-300"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                      >
                                        <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                                      </svg>
                                    </div>
                                    <div className="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-gray-700"></div>
                                  </div>
                                  <div className="mt-3 sm:pe-8">
                                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                      {itemLifeCycle.from}
                                    </h3>
                                    <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                                      {dateTimeGateInOutHelper(
                                        itemLifeCycle.dateGateIn
                                      )}
                                    </time>
                                    <div>
                                      + Người nhận container:{" "}
                                      {itemLifeCycle.receiver}
                                    </div>
                                    <div>
                                      + Phân loại: {itemLifeCycle.noteGateIn}
                                    </div>
                                  </div>
                                </li>
                                {itemLifeCycle.alreadyGateIn &&
                                  itemLifeCycle.alreadyGateOut && (
                                    <li className="relative mb-6 sm:mb-0">
                                      <div className="flex items-center">
                                        <div className="z-10 flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-0 ring-white dark:bg-blue-900 sm:ring-8 dark:ring-gray-900 shrink-0">
                                          <svg
                                            className="w-2.5 h-2.5 text-blue-800 dark:text-blue-300"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                          >
                                            <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                                          </svg>
                                        </div>
                                        <div className="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-gray-700"></div>
                                      </div>
                                      <div className="mt-3 sm:pe-8">
                                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                          {itemLifeCycle.to}
                                        </h3>
                                        <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                                          {dateTimeGateInOutHelper(
                                            itemLifeCycle.dateGateOut
                                          )}
                                        </time>
                                        <div>
                                          + Người giao container:{" "}
                                          {itemLifeCycle.deliver}
                                        </div>
                                        <div>
                                          + Ghi chú Gate Out:{" "}
                                          {itemLifeCycle.noteGateOut}
                                        </div>
                                      </div>
                                    </li>
                                  )}
                              </ol>
                            </li>
                          </ol>
                        ))}
                      </div>
                    </div>
                  </div>
                </Tab>
              ) : (
                <Tab label="Không có thông tin Container">
                  <div className="py-4">
                    <h2 className="text-lg font-medium mb-2">
                      Không có thông tin
                    </h2>
                  </div>
                </Tab>
              )}
              {userAuth?.role === 0 && shouldRenderTabContainer ? (
                <Tab
                  label={`${
                    userAuth?.role === 0 && "Danh mục Thu/Chi container"
                  }`}
                >
                  <div className="py-4">
                    <h2 className="text-lg font-medium mb-2">
                      Thu/Chi container
                    </h2>
                    <div className="my-6 ">
                      <ol className="items-center flex flex-wrap w-full space-y-4 sm:flex sm:space-x-8 sm:space-y-0 rtl:space-x-reverse sm:my-12 mb-4">
                        <StepperComponent
                          numberStep={1}
                          nameStep="Giá mua"
                          data={new Intl.NumberFormat("de-DE").format(
                            parseInt(
                              !data?.buyingPrice ? "0" : data?.buyingPrice
                            )
                          )}
                        />
                        <StepperComponent
                          numberStep={2}
                          nameStep="Giá bán dự kiến"
                          data={new Intl.NumberFormat("de-DE").format(
                            parseInt(
                              !data?.sellingPrice ? "0" : data?.sellingPrice
                            )
                          )}
                        />
                        <StepperComponent
                          numberStep={3}
                          nameStep="Giá bán thực tế"
                          data={new Intl.NumberFormat("de-DE").format(
                            parseInt(
                              !data?.actualSellingPrice
                                ? "0"
                                : data?.actualSellingPrice
                            )
                          )}
                        />
                        <StepperComponent
                          numberStep={4}
                          nameStep="Số tiền cọc"
                          data={new Intl.NumberFormat("de-DE").format(
                            parseInt(
                              !data?.depositAmount ? "0" : data?.depositAmount
                            )
                          )}
                        />
                        <StepperComponent
                          numberStep={5}
                          nameStep="Chi phí nâng hạ"
                          data={new Intl.NumberFormat("de-DE").format(
                            parseInt(!data?.LOLOCost ? "0" : data?.LOLOCost)
                          )}
                        />
                        <StepperComponent
                          numberStep={6}
                          nameStep="Chi phí kéo"
                          data={new Intl.NumberFormat("de-DE").format(
                            parseInt(!data?.towingCost ? "0" : data?.towingCost)
                          )}
                        />
                        <StepperComponent
                          numberStep={7}
                          nameStep="Chi phí sửa chữa"
                          data={new Intl.NumberFormat("de-DE").format(
                            parseInt(!data?.repairCost ? "0" : data?.repairCost)
                          )}
                        />
                      </ol>
                      <ol className="items-center flex flex-wrap w-full space-y-4 sm:flex sm:space-x-8 sm:space-y-0 rtl:space-x-reverse sm:my-12">
                        <StepperComponent
                          numberStep={9}
                          nameStep="Chi phí phát sinh"
                          data={new Intl.NumberFormat("de-DE").format(
                            parseInt(!data?.incurCost ? "0" : data?.incurCost)
                          )}
                        />
                        <StepperComponent
                          numberStep={10}
                          nameStep="Chi phí khác"
                          data={new Intl.NumberFormat("de-DE").format(
                            parseInt(!data?.otherCosts ? "0" : data?.otherCosts)
                          )}
                        />
                        <StepperComponent
                          numberStep={11}
                          nameStep="Doanh thu Gate In"
                          data={new Intl.NumberFormat("de-DE").format(
                            parseInt(
                              !data?.gateInRevenue ? "0" : data.gateInRevenue
                            )
                          )}
                        />
                        <StepperComponent
                          numberStep={12}
                          nameStep="Doanh thu Gate Out"
                          data={new Intl.NumberFormat("de-DE").format(
                            parseInt(
                              !data?.gateOutRevenue ? "0" : data.gateOutRevenue
                            )
                          )}
                        />
                        <StepperComponent
                          numberStep={13}
                          nameStep="Lợi nhuận Container"
                          data={new Intl.NumberFormat("de-DE").format(
                            parseInt(!containerRevenue ? "0" : containerRevenue)
                          )}
                        />
                      </ol>
                    </div>
                  </div>
                </Tab>
              ) : (
                <Tab label="Không có thông tin Thu/Chi">
                  <div className="py-4">
                    <h2 className="text-lg font-medium mb-2">
                      Không có thông tin
                    </h2>
                  </div>
                </Tab>
              )}
              {shouldRenderTabBookingSale ? (
                <Tab label="Danh mục thông tin booking bán">
                  <div className="py-4">
                    <h2 className="text-lg font-medium mb-2">
                      Thông tin booking bán
                    </h2>
                    <div className="overflow-hidden overflow-x-auto">
                      <div className="mt-6">
                        <ol className="items-center flex flex-wrap w-full gap-5 sm:flex sm:gap-5 rtl:space-x-reverse">
                          <StepperComponent
                            numberStep={1}
                            nameStep="Số booking bán"
                            data={bookingSalesData?.bookingManySaleNumber}
                          />
                          <StepperComponent
                            numberStep={2}
                            nameStep="Số container booking"
                            data={bookingSalesData?.containerNumberBookingSale}
                          />{" "}
                          <StepperComponent
                            numberStep={3}
                            nameStep="Nhà cung cấp"
                            data={bookingSalesData?.supplier?.label}
                          />
                          <StepperComponent
                            numberStep={4}
                            nameStep="Vị trí"
                            data={bookingSalesData?.serviceSupplier?.label}
                          />
                          {data?.containerCondition ? (
                            <StepperComponent
                              numberStep={5}
                              nameStep="Tình trạng container"
                              data={bookingSalesData?.containerCondition}
                            />
                          ) : null}
                          <StepperComponent
                            numberStep={6}
                            nameStep="Kích thước container"
                            data={bookingSalesData?.size}
                          />
                        </ol>
                        <ol className="items-center flex flex-wrap w-full gap-5 sm:flex sm:gap-5 rtl:space-x-reverse mt-5 sm:mt-12">
                          <StepperComponent
                            numberStep={7}
                            nameStep="Người bán"
                            data={bookingSalesData?.seller?.label}
                          />
                          {(userAuth?.role === 0 ||
                            userAuth?._id ===
                              bookingSalesData?.seller?.value) && (
                            <StepperComponent
                              numberStep={8}
                              nameStep="Người mua"
                              data={bookingSalesData?.buyer?.label}
                            />
                          )}

                          <StepperComponent
                            numberStep={9}
                            nameStep="Số lượng container"
                            data={bookingSalesData?.contQuantity}
                          />
                          <StepperComponent
                            numberStep={10}
                            nameStep="Số lượng container còn lại"
                            data={bookingSalesData?.remainingQuantity}
                          />
                          <StepperComponent
                            numberStep={11}
                            nameStep="Ngày lấy container"
                            data={dateStringHelper(
                              bookingSalesData?.bookingDate
                            )}
                          />
                          <StepperComponent
                            numberStep={12}
                            nameStep="Số hotline"
                            data={bookingSalesData?.hotline}
                          />
                        </ol>
                      </div>
                      {userAuth?.role === 0 ? (
                        <div className="flex gap-5 sm:flex sm:gap-5 mt-6 sm:mt-12">
                          <StepperComponent
                            numberStep={13}
                            nameStep="Khách hàng"
                            data={bookingSalesData?.buyer?.label}
                          />
                          <StepperComponent
                            numberStep={14}
                            nameStep="Giá bán"
                            data={new Intl.NumberFormat("de-DE").format(
                              parseInt(
                                !bookingSalesData?.sellingPrice
                                  ? "0"
                                  : bookingSalesData?.sellingPrice
                              )
                            )}
                          />
                          <StepperComponent
                            numberStep={15}
                            nameStep="VAT(%)"
                            data={bookingSalesData?.VAT}
                          />
                        </div>
                      ) : (
                        <div className="flex gap-5 sm:flex sm:gap-5 mt-6 sm:mt-12">
                          <StepperComponent
                            numberStep={13}
                            nameStep="VAT(%)"
                            data={bookingSalesData?.VAT}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Tab>
              ) : (
                <Tab label="Không có thông tin">
                  <div className="py-4">
                    <h2 className="text-lg font-medium mb-2">
                      Không có thông tin
                    </h2>
                  </div>
                </Tab>
              )}
            </Tabs>
          </div>
        </div>
      )}
    </>
  );
};
